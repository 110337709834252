import authApiService from './auth-api-service';
import { redirectToIDP } from './utils';

export const fetchAuthToken = async () => {
  const request = {
    url: `api/getIdpToken/tokeninfo`,
  };
  return fetchTokenInfoByRequest(request);
};

export const fetchTokenInfoByRequest = async (request) => {
  const response = await authApiService.get(request);
  if (!response.data || response.data === 'null' || !response.data.AccessToken || response.data.AccessToken === 'null') {
    redirectToIDP();
  }
  sessionStorage.setItem("tokenInfo", response.data.RefreshToken);
  return response.data.AccessToken;
}

export const extendAuthToken = async () => {
  const request = {
    url: `api/getIdpToken/refresh`,
  };
  return fetchTokenByRequest(request);
};

export const fetchTokenByRequest = async (request) => {
  const tokenInfo = sessionStorage.getItem("tokenInfo");
  if(!tokenInfo || tokenInfo === '')
  {
    redirectToIDP();
  }
  else
  {
    request.data = {
      "RefreshToken": tokenInfo,
    }
    const response = await authApiService.post(request);
    if (!response.data || response.data === 'null') {
      redirectToIDP();
    }
    return response.data;
  }
  return null;
}
