import { ApiStatus } from "foundation/CertificationApiCore/client/constants";

export const getExamFormats = state => state.application.examFormats.entity;

export const getExamFormatsStatus = state =>
  state.application.examFormats ? state.application.examFormats.status : ApiStatus.Idle;

export const getExamFormatsError = state => state.application.examFormats.error;

export const getExamFormatType = state => {
  const exam = state.application.examFormats.examEntity;
  if(exam && exam.examVendorEnum)
  {
    if(exam.examTypeEnum)
      return `${exam.examVendorEnum}.${exam.examTypeEnum}${exam.isPilotExam ? '.Pilot' :''}`;
    return `${exam.examVendorEnum}`;
  }
  return null;
}

export const getExamEntity = state => state.application.examFormats.examEntity;