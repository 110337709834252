import React from 'react';
import { Modal } from 'react-bootstrap';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import 'assets/styles/modal.scss';
import FileDropZone from './fileDropZone';

const UploadModal = props => {
  const {  show, onHide, fields, uploadDocument, formProp } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      dialogClassName="dsm"
      centered
      aria-labelledby="UploadModalTitle"
      aria-describedby="UploadModalDesc"
    >
      <Modal.Header closeButton>
        <Modal.Title id="UploadModalTitle">
          <Text field={fields.Header} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="UploadModalDesc">
        <FileDropZone onClose={onHide} fields={fields} uploadDocument={uploadDocument} formProp={formProp} />
      </Modal.Body>
    </Modal>
  );
};


export default UploadModal;

