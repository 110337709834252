import React from 'react';
import i18n from 'i18next';
import { connect } from 'formik';
import { SelectControl } from './SelectControl';
import { FieldError, FieldGroup, FieldLabel } from '../FieldGroup';
import { getFieldError, getElementId, getUniqueId } from '../utils';

class DateSelectComponent extends React.PureComponent {

  state = {
    year: undefined,
    month: undefined,
    date: undefined,
    monthOptions: [],
    yearOptions: [],
    dateOptions:[],
    initialized: false,
  };

  uid = getUniqueId();

  componentDidMount() {
    this.updateState();

    this.setState({
      yearOptions: this.getYearOptions(),
      monthOptions: this.getMonthOptions(),
      dateOptions: this.getDateOptions(),
      initialized: true,
    });
  }

  componentDidUpdate() {
    this.updateState();
  }


  makeDate(year,month,date) {
    const mm = (`0${month}`).slice(-2);
    const dt = (`0${date}`).slice(-2);
    return `${year}-${mm}-${dt}`;
  }

  emitDateChange = (year,month,date) => {
    const { dateField, formik } = this.props;
    const value = this.makeDate(year,month,date);
    formik.setFieldValue(dateField, value);
  }

  handleMonthChange = e => {
    const month = e.value;
    this.setState(({year,date}) => {
      if (year !== undefined && date !== undefined) {
        this.emitDateChange(year,month,date);
      }
      return {month};
    });
    this.setState({
      yearOptions: this.getYearOptions(month),
    });
  }

  handleYearChange = e => {
    const year = e.value;
    this.setState(({month,date}) => {
      if (month !== undefined && date !== undefined) {
        this.emitDateChange(year,month,date);
      }
      return {year};
    });
  }

  handleDateChange = e => {
    const date = e.value;
    this.setState(({year,month}) => {
      if (month !== undefined && year !== undefined) {
        this.emitDateChange(year,month,date);
      }
      return {date};
    });
    this.setState({
      yearOptions: this.updateYearOptionsByDate(date),
    });
  }

  updateState() {
    const { formik, dateField } = this.props;
    const dateValue = formik.values[dateField];
    if (dateValue) {
      const splitedDate = dateValue.split('T')[0].split('-');
      this.setState({
        year: parseInt(splitedDate[0], 10),
        month: parseInt(splitedDate[1], 10),
        date: parseInt(splitedDate[2], 10),
      });
    }
  }

  getSequence = (min, max, sortOrder) => {
    if (min && max && min < max) {
      let result = [];

      for (let i = min; i <= max; i++) {
        result.push(i);
      }
      if (!!sortOrder && sortOrder.toLowerCase() === 'desc') {
        result = result.sort((a, b) => b - a);
      }
      return result;
    }

    return null;
  }

  getYearOptions = (month) => {
    const monthValue = month ?? this.state.month;
    return this.updateYearOptions(monthValue,this.state.date);
  }

  updateYearOptionsByDate = (date) => {
    return this.updateYearOptions(this.state.month,date);
  }

  updateYearOptions = (month,date) => {
    const currYear = new Date().getFullYear();
    const { dateField, formik } = this.props;
    let maxYear =  currYear - 18;
    const minYear = currYear - 90;
    if( month !== undefined && date !== undefined)
    {
      const minAgeDate = new Date();
      minAgeDate.setFullYear(minAgeDate.getFullYear() - 18)
      const uiMaxDate = new Date(maxYear,month - 1,date);
      if(uiMaxDate > minAgeDate)
      {
        if(this.state.year >= maxYear)
        {
          this.setState({
            year:  undefined,
          });
          formik.setFieldValue(dateField, null);
        }
        maxYear--;
      }
    }
    return this.getSequence(minYear,maxYear,"desc");
  }

  getMonthOptions = () => {
    const { monthList } = this.props;
    return monthList.map((name, index) => ({value: index + 1, label: name}));
  }

  getDateOptions = () => {
    return this.getSequence(1,31);
  }

  render() {
    const { dateField, yearLabel,monthLabel, dateLabel, required, disabled,   formik } = this.props;
    const { year, month, date,  yearOptions, monthOptions, dateOptions, initialized} = this.state;
    const error = getFieldError(formik, dateField);
    const id = getElementId('dateSelect', dateField);

    if (!initialized) {
      return null;
    }

    return (
      <>
        <FieldGroup
          required={required}
          disabled={disabled}
          error={error}
        >
          <div className="row">
            <div className="col">
              {dateLabel && <FieldLabel id={id} label={dateLabel} required={required}/>}
              <SelectControl
                options={dateOptions.map(option => ({ value: option, label: option }))}
                value={date}
                placeholder={i18n.t("cert-app.Common.SelectPlaceholder")}
                disabled={disabled}
                onChange={this.handleDateChange}
              />
            </div>
            <div className="col col-6">
              {monthLabel && <FieldLabel id={id} label={monthLabel} required={required}/>}
              <SelectControl
                options={monthOptions}
                value={month}
                placeholder={i18n.t("cert-app.Common.SelectPlaceholder")}
                disabled={disabled}
                onChange={this.handleMonthChange}
              />
            </div>
            <div className="col">
              {yearLabel && <FieldLabel id={id} label={yearLabel} required={required}/>}
              <SelectControl
                options={yearOptions.map(option => ({ value: option, label: option }))}
                value={year}
                placeholder={i18n.t("cert-app.Common.SelectPlaceholder")}
                disabled={disabled}
                onChange={this.handleYearChange}
              />
            </div>
          </div>
          <FieldError error={error} fieldName={dateField} />
        </FieldGroup>
      </>
    );
  }
}

export const DateSelect = connect(DateSelectComponent);
