import { call, put, takeLatest, select } from "redux-saga/effects";
import { getPhones } from 'foundation/Profile/client/Phone/accessors';
import { getProfilePhones, getProfilePhonesApi } from 'foundation/Profile/client/Profile/accessors';
import { FETCH_PROFILE_SUCCESS } from "../Profile/actions";
import * as actions from "./actions";
import * as api from "./api";


function* fetchPhones() {
  try {
    const state = yield select();
    let phones = getPhones(state) || getProfilePhones(state);

    if (!phones) {
      const data = yield call(api.fetchPhones, getProfilePhonesApi(state));
      phones = data && data.resources;
    }
    phones = localPhonesMapping(phones);
    yield put(actions.fetchPhonesSuccess(phones));

  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.fetchPhonesFailure());
  }
}
const PhoneNumberTypes = {
  1: 'Home',
  2: 'Work',
  3: 'Cell',
  Home: 1,
  Work: 2,
  Cell: 3,
}
function localPhonesMapping(phones) {
  return phones.map((phone) => (localPhoneMapping(phone)));
}

function localPhoneMapping(phone) {
  return ({
    ...phone,
    phoneNumber: phone.number,
    phoneExtension: phone.extension,
    phoneCountryCode: phone.countryCode,
    countryId: phone.countryId,
    isPrimary: phone.isPreferred,
    phoneAreaCode: phone.areaCode,
    PhoneNumberType: PhoneNumberTypes[phone.type.id],
  });
}

function serverPhoneMapping(phone)
{
  return ({
    number: phone.phoneNumber,
    extension: phone.phoneExtension,
    countryCode: phone.phoneCountryCode,
    isPreferred: phone.isPrimary,
    areaCode: phone.phoneAreaCode,
    countryId: phone.countryId,
    type: {id: PhoneNumberTypes[phone.PhoneNumberType]},
  });
}

const revertPrimary = (phone) => ({...phone, isPrimary: false});

function* createPhone(action) {
  try {
    const state = yield select();
    const savedPhone = yield call(api.createPhone, {
      data: serverPhoneMapping({...action.payload.data}),
      url: getProfilePhonesApi(state),
    });

    if (savedPhone) {
      const localPhone = localPhoneMapping(savedPhone);
      const putchUpdated = phone => revertPrimary(phone);
      const phoneList = getPhones(state).map(putchUpdated)
      yield put(actions.createPhoneSuccess([...phoneList, localPhone]));
    } else {
      yield put(actions.createPhoneFailure(action.payload.data));
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.createPhoneFailure(action.payload.data));
    if(err.response !== null && err.response.status === 422)
    {
      action.payload.onFailure(action.payload.setErrors);
    }
  }
}

function* updatePhone(action) {
  try {
    const state = yield select();
    const link = action.payload.data._links.self;
    const savedPhone = yield call(api.updatePhone, {
      data: serverPhoneMapping({...action.payload.data}),
      url: link.href,
    });

    if (savedPhone) {
      const localPhone = localPhoneMapping(savedPhone);
      const putchUpdated = phone => phone._links.self.href === link.href ? localPhone : revertPrimary(phone);
      yield put(actions.updatePhoneSuccess(getPhones(state).map(putchUpdated)));
    } else {
      yield put(actions.updatePhoneFailure(action.payload.data));
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.updatePhoneFailure(action.payload.data));
    if(err.response !== null && err.response.status === 422)
    {
      action.payload.onFailure(action.payload.setErrors);
    }
  }
}

function* watchFetchPhoneRequest() {
  yield takeLatest(FETCH_PROFILE_SUCCESS, fetchPhones);
}
function* watchCreatePhoneRequest() {
  yield takeLatest(actions.CREATE_PHONE_REQUEST, createPhone);
}
function* watchUpdatePhoneRequest() {
  yield takeLatest(actions.UPDATE_PHONE_REQUEST, updatePhone);
}

const phoneSagaWatchers = [
  watchFetchPhoneRequest,
  watchCreatePhoneRequest,
  watchUpdatePhoneRequest,
];
export default phoneSagaWatchers;
