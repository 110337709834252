import { createAction } from 'redux-actions';

export const FETCH_AGILE_CERTIFICATION_REQUEST = 'FETCH_AGILE_CERTIFICATION_REQUEST';
export const FETCH_AGILE_CERTIFICATOIN_SUCCESS = 'FETCH_AGILE_CERTIFICATOIN_SUCCESS';
export const FETCH_AGILE_CERTIFICATOIN_FAILURE = 'FETCH_AGILE_CERTIFICATOIN_FAILURE';
export const UPLOAD_CERTIFICATION_REQUEST = 'UPLOAD_CERTIFICATION_REQUEST';
export const UPLOAD_CERTIFICATION_SUCCESS = 'UPLOAD_CERTIFICATION_SUCCESS';
export const UPLOAD_CERTIFICATION_FAILURE = 'UPLOAD_CERTIFICATION_FAILURE';
export const DELETE_CERTIFICATION_REQUEST = 'DELETE_CERTIFICATION_REQUEST';
export const DELETE_CERTIFICATION_SUCCESS = 'DELETE_CERTIFICATION_SUCCESS';
export const DELETE_CERTIFICATION_FAILURE = 'DELETE_CERTIFICATION_FAILURE';
export const POST_AGILE_CERTIFICATION_REQUEST = 'POST_AGILE_CERTIFICATION_REQUEST';
export const POST_AGILE_CERTIFICATION_SUCCESS = 'POST_AGILE_CERTIFICATION_SUCCESS';
export const POST_AGILE_CERTIFICATION_FAILURE = 'POST_AGILE_CERTIFICATION_FAILURE';
export const DOWNLOAD_CERTIFICATION_REQUEST = 'DOWNLOAD_CERTIFICATION_REQUEST';
export const DOWNLOAD_CERTIFICATION_SUCCESS = 'DOWNLOAD_CERTIFICATION_SUCCESS';
export const DOWNLOAD_CERTIFICATION_FAILURE = 'DOWNLOAD_CERTIFICATION_FAILURE';


export const fetchAgileCertification = createAction(FETCH_AGILE_CERTIFICATION_REQUEST, data => data);
export const fetchAgileCertificationSuccess = createAction(FETCH_AGILE_CERTIFICATOIN_SUCCESS, data => data);
export const fetchAgileCertificationFailure = createAction(FETCH_AGILE_CERTIFICATOIN_FAILURE, data => data);

export const uploadCertification = createAction(UPLOAD_CERTIFICATION_REQUEST, data => data);
export const uploadCertificationSuccess = createAction(UPLOAD_CERTIFICATION_SUCCESS, data => data);
export const uploadCertificationFailure = createAction(UPLOAD_CERTIFICATION_FAILURE, data => data);

export const deleteCertification = createAction(DELETE_CERTIFICATION_REQUEST, data => data);
export const deleteCertificationSuccess = createAction(DELETE_CERTIFICATION_SUCCESS, data => data);
export const deleteCertificationFailure = createAction(DELETE_CERTIFICATION_FAILURE, data => data);

export const postAgileCertificationRequest = createAction(POST_AGILE_CERTIFICATION_REQUEST, data => data);
export const postAgileCertificationSuccess = createAction(POST_AGILE_CERTIFICATION_SUCCESS, data => data);
export const postAgileCertificationFailure = createAction(POST_AGILE_CERTIFICATION_FAILURE, data => data);

export const downloadCertification = createAction(DOWNLOAD_CERTIFICATION_REQUEST, data => data);
export const downloadCertificationSuccess = createAction(DOWNLOAD_CERTIFICATION_SUCCESS, data => data);
export const downloadCertificationFailure = createAction(DOWNLOAD_CERTIFICATION_FAILURE, data => data);