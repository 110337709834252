import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { InputField, SaveButton } from 'foundation/FormFields/client/components';
import { getIsSavingStatus } from 'foundation/Application/client/Experience/accessors';
import * as certificationActions from "foundation/Application/client/Certification/actions";
import FormTracking from 'foundation/Analytics/client/components/AdobeAnalytics/formTracking';
import FileUpload from 'foundation/FormFields/client/components/FileUpload';

class CertificationDocumentEdit extends React.Component {
  state = {
    isFormStart: false,
  };

  handleSaveClick = (formikProps) => (e) => {
    e.preventDefault();
    formikProps.submitForm();
  };

  handleSubmit = (values) => {
    const { saveDocument } = this.props;
    saveDocument({
      ...values,
    });
  }

  handleFocus = () => {
    this.setState({
      isFormStart: true,
    });
  }

  renderForm = (formikProps) => {
    const { certDocument, fields, saving } = this.props;
    return (
      <Form className="edit-mode experience mt-10" onFocus={this.handleFocus}>
        <FormTracking
          formik={formikProps}
          formName="agile certification document"
          formStartState={this.state.isFormStart}
        />
        <div className="w-100">
          <InputField
            fieldName='documentInfo.certificationProvider'
            label={<Text field={fields.Provider}/>}
            required
          />
          <InputField
            fieldName='documentInfo.certificationName'
            label={<Text field={fields.Name}/>}
            required
          />
        </div>
        <div className="form-row">
          <div className="col-md">
            <InputField
              fieldName='documentInfo.earnedDate'
              label={<Text field={fields.EarnedDate}/>}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md">
            <InputField
              fieldName='documentInfo.certificationProviderUrl'
              label={<Text field={fields.ProviderUrl}/>}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md">
            <FileUpload fields={fields} document={certDocument} formProp={formikProps} isViewMode={true}/>
          </div>
        </div>
        <div className="d-flex align-items-end mt-4">
          <SaveButton
            className="btn"
            saving={saving}
            label={i18n.t('cert-app.Experience.ProjectExperience.SaveExperience')}
            onClick={this.handleSaveClick(formikProps)}
          />
        </div>
      </Form>
    )
  }

  render() {
    const { certDocument } = this.props;
    const initialValues = {
      ...certDocument,
    };
    if(!initialValues.documentInfo)
    {
      initialValues.documentInfo = {
        certificationProvider: '',
        certificationName: '',
        earnedDate: '',
        certificationProviderUrl: '',
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validateOnChange
      >
        {this.renderForm}
      </Formik>
    )
  }
}

const mapStateToProps = state => ({
  saving: getIsSavingStatus(state),
});

const mapDispatchToProps = dispatch => ({
  saveDocument: (data) => dispatch(certificationActions.postAgileCertificationRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CertificationDocumentEdit);