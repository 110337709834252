import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcon } from '../../../../../Project/Certification/client/assets/icons/upload.svg';
import UploadModal from './uploadModal';
import DocumentView from './view';
import "./fileUpload.scss";

class FileUpload extends React.Component {
  state = {
    isOpen: false,
  };

  documentView(document, fields, isViewMode) {
    return (
      <DocumentView
        key={document._links.self.href}
        document={document}
        fields={fields}
        isViewMode={isViewMode}
      />
    );
  }

  handleShow = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { fields, document, isViewMode, formProp } = this.props;
    const { isOpen } = this.state;
    if (!fields) return <div className="static-height" />;

    const isUploaded = document?.isDocumentAvailable;
    return (
      <>
        <div>
          <Row>
            <Col sm={3}>
              {!isUploaded  ? (
                <div className="dsm d-flex justify-content-end p-0">
                  <Button
                    as="a"
                    variant="link"
                    onClick={this.handleShow}
                    className="btn btn-link btn-md with-icon uploadBtn"
                  >
                    <div className="btn-icon uploadIcon">
                      <DownloadIcon />
                    </div>
                    <div>{fields.UploadLabel.value}</div>
                  </Button>
                </div>
              ) : (
                this.documentView(document, fields, isViewMode)
              )}
            </Col>
          </Row>
        </div>
        <UploadModal
          show={isOpen}
          onHide={this.handleClose}
          document={document}
          fields={fields}
          formProp={formProp}
        />
      </>
    );
  }
}

export default FileUpload;
