import { call, put, takeLatest, select } from "redux-saga/effects";
import { getProfileAddressPreferences, getAddressPreferredApi, getAddressApi } from '../Profile/accessors';
import { FETCH_PROFILE_SUCCESS } from "../Profile/actions";
import { getAddresses } from './accessors';
import * as actions from "./actions";
import * as api from "./api";

function* fetchAddresses() {
  try {
    const state = yield select();
    const addresses = getAddresses(state) || getProfileAddressPreferences(state);
    yield put(actions.fetchAddressesSuccess(localAddressesMapping(addresses)));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.fetchAddressesFailure());
  }
}

function localAddressesMapping(addresses) {
  return addresses.map((address) => (localAddressMapping(address)));
}

function localAddressMapping(address) {
  return ({
    ...address,
    address:
    {
      ...address.address,
      countryCode: address.address.country.countryCode3,
      AddressLocationType: address.address.locationType.id === 1 ? 'Residential' : 'Commercial',
    },
  });
}

function localAddressFromApi(data) {
  return ({
    ...data,
    countryCode: data.country.countryCode3,
    AddressLocationType: data.locationType.id === 1 ? 'Residential' : 'Commercial',
    AddressType: data.type.id === 1 ? 'Home' : 'Work',
    addressTypeEnum: data.type.id === 1 ? 'Home' : 'Work',
  });
}

function localAddressListEntityFromApi(data) {
  return ({
    address: localAddressFromApi(data),
    addressTypeEnum: data.type.id === 1 ? 'Home' : 'Work',
    id: data.id,
    _links: data._links,
  });
}

function serverAddressMapping(address) {
  return ({
    ...address,
    isPreferred: true,
    country: {
      countryCode3: address.countryCode,
    },
    locationType: {
      id:  address.AddressType === 'Home' ? 1: 2,
    },
    type:{
      id: address.AddressType === 'Home' ? 1: 2,
    },
  });
}

function* saveAddress(action) {
  try {
    const state = yield select();
    const { profileAddress, values } = action.payload;
    let data;
    let updatedAddresses = getAddresses(state).map(address => address);

    if (profileAddress) {
      const updateProfileAddressLink = profileAddress._links.address?.href ?? profileAddress._links.self?.href;
      data = yield call(api.updateAddress, {
        url: updateProfileAddressLink,
        data: serverAddressMapping(values),
      });

      if (data) {
        data = localAddressFromApi(data);
        const { _links, ...newAddressData } = data;

        updatedAddresses = updatedAddresses.map(address =>
          (address._links.address?.href?.toLowerCase() ?? address._links.self?.href?.toLowerCase()) === _links.self.href.toLowerCase()
            ? { ...address, address: { ...newAddressData } }
            : address,
        );
      }

    } else {
      const createProfileAddressLink = getAddressApi(state);

      // TODO Clarify where should we get AddressLocationType
      data = yield call(api.createAddress, {
        url: createProfileAddressLink,
        data: {
          ...serverAddressMapping(values),
        },
      });
      if (data) {
        const createProfilePreferrenceAddressLink = getAddressPreferredApi(state);
        yield call(api.createAddress, {
          url: createProfilePreferrenceAddressLink,
          data: {
            ...data,
          },
        });
        data = localAddressListEntityFromApi(data);
        updatedAddresses.push({ ...data });
      }
    }

    if (data) {
      yield put(actions.saveAddressSuccess(updatedAddresses));
    } else {
      yield put(actions.saveAddressFailure());
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.saveAddressFailure(action.payload));
  }
}

function* watchFetchAddressRequest() {
  yield takeLatest(FETCH_PROFILE_SUCCESS, fetchAddresses);
}
function* watchSaveAddressRequest() {
  yield takeLatest(actions.SAVE_ADDRESS_REQUEST, saveAddress);
}

const addressSagaWatchers = [
  watchFetchAddressRequest,
  watchSaveAddressRequest,
];
export default addressSagaWatchers;
