import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import i18n from 'i18next';
import { Button } from '@pmi/dsm-react-bs4';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import validator from "validator";
import * as apiProfileActions from "foundation/Profile/client/Address/actions";
import * as apiAppActions from "foundation/Application/client/Address/actions";
import * as nameApiActions from "foundation/Application/client/Name/actions";
import { getTypedProfileAddress } from 'foundation/Profile/client/Address/accessors';
import { getProfileDob } from 'foundation/Profile/client/Profile/accessors';
import { getCountryOrigin } from 'foundation/Profile/client/Profile/accessors';
import { InputField, CountrySelect, SaveButton, StateSelect, DateSelect } from "foundation/FormFields/client/components";
import { getCountriesInfo, getAllCountries, getFilteredCountries } from 'foundation/Profile/client/Metadata/countries';
import { isRetakeFlow } from 'foundation/Application/client/Application/accessors';
import { getListsSettings } from 'foundation/SitecoreSettings/client/accessors';
import { ReactComponent as CircleAlertIcon } from '@pmi/dsm-react-bs4/dist/assets/icons/24/circle-alert.svg';
import { getWorkflowType } from 'foundation/Application/client/certtype-storage';
import { Col, Row } from 'react-bootstrap';
import FormTracking from 'foundation/Analytics/client/components/AdobeAnalytics/formTracking';
import { FieldGroup } from 'foundation/FormFields/client/components';
import IdentificationFormsTooltip from '../Name/identificationTooltip'
import * as uiActions from "./actions";
import './addressOption.scss';

class AddressEdit extends React.Component {
  state = {
    addresses: [],
    inputAddressIndex: -1,
    hasSavedAddresses: false,
    initialized: false,
    allowedAddressesTypes: ['Home'],
    defaultSelectedAddressType: 'Home',
    isFormStart: false,
  };

  validationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().max(20),
    lastName: Yup.string().nullable()
      .max(30)
      .when('firstName', {
        is: (value) => !value || value.length === 0,
        then: Yup.string().required(this.props.fields.SpecifyFirstLastName.value),
      }),
    countryCode: Yup.string()
      .required(this.props.fields.CountryIsRequired.value)
      .nullable(),
    address1: Yup.string()
      .required(this.props.fields.AddressIsRequired.value)
      .max(100)
      .nullable(),
    city: Yup.string()
      .required(this.props.fields.CityDistrictIsRequired.value)
      .max(50)
      .nullable(),
    state: Yup.string()
      .required(this.props.fields.StateProvinceIsRequired.value)
      .max(50)
      .nullable(),
    postalCode: Yup.string()
      .required(this.props.fields.ZipPostalCodeIsRequired.value)
      .max(25)
      .nullable()
      .when('countryCode', (countryCode, schema) => {
        return this.props.fields.PostalValidation.some(validation => validation.fields.SelectionCode.value === countryCode) ?
          schema.matches(new RegExp(this.props.fields.PostalValidation.find(validation => validation.fields.SelectionCode.value === countryCode).fields.RegularExpression.value),this.props.fields.PostalValidation.find(validation => validation.fields.SelectionCode.value === countryCode).fields.ErrorMessage.value) : schema
      }),
    birthdate: Yup.string()
      .when([],{
        is: () => !this.IsEligibleToPayFlow(),
        then: Yup.string().required(this.props.fields.BirthDateRequired.value),
      })
      .test("valid date",this.props.fields.BirthMonthRequired.value, (value) => { return !value  || validator.isDate(value.split('T')[0])})
      .test("valid date age",this.props.fields.BirthYearRequired.value, (value) => {  const minAgeDate = new Date();
        minAgeDate.setFullYear(minAgeDate.getFullYear() - 18); return !value || validator.toDate(value.split('T')[0]) < minAgeDate; }),
  });

  IsEligibleToPayFlow() {
    const workflowType = getWorkflowType();
    return workflowType === 'ETP';
  }

  componentDidMount() {
    const { examAddress } = this.props;
    const { allowedAddressesTypes, defaultSelectedAddressType } = this.state;
    const addresses = allowedAddressesTypes.map(this.formatInputAddress);
    const isExamAddress = addr => addr.examAddress;
    const isProfileAddress = addr => addr.profileAddress;
    const isDefaultAddress = addr => addr.addressTypeEnum === defaultSelectedAddressType;
    const savedAddressIndex = examAddress ? addresses.findIndex(isExamAddress) : addresses.findIndex(isProfileAddress);
    const inputAddressIndex = savedAddressIndex >= 0 ? savedAddressIndex : addresses.findIndex(isDefaultAddress);
    const hasSavedAddresses = savedAddressIndex >= 0;

    this.setState({
      addresses,
      inputAddressIndex,
      hasSavedAddresses,
      initialized: true,
    });
  }

  formatInputAddress = addressTypeEnum => {
    const { examAddress, getTypedProfileAddress, countryOrigin } = this.props;
    const profileAddress = getTypedProfileAddress(addressTypeEnum);

    const result = {
      addressTypeEnum,
      countryCode: countryOrigin,
    };

    if (profileAddress) {
      Object.assign(result, {
        ...profileAddress.address,
        profileAddress,
      });
    }

    if (examAddress && examAddress.AddressType === addressTypeEnum) {
      Object.assign(result, {
        ...examAddress,
        examAddress,
      });
    }

    return result;
  }

  cancelEditing = (e) => {
    e.preventDefault();
    const { setIsOpen } = this.props;
    setIsOpen(false);
  }

  handleSaveClick = (formikProps) => (e) => {
    e.preventDefault();
    formikProps.submitForm();
  };

  handleSubmit = (values) => {
    const { saveAppAddress, saveProfileAddress, saveNameOnIdentification } = this.props;
    const commonProperties = {
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      countryCode: values.countryCode,
      AddressType: values.addressTypeEnum,
    }
    saveProfileAddress({
      values: {
        ...commonProperties,
        AddressLocationType: values.AddressLocationType,
      },
      profileAddress: values.profileAddress,
    });

    saveAppAddress({
      ...commonProperties,
      address3: values.address3,
    });
    saveNameOnIdentification({
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      birthdate: values.birthdate,
    });
  }

  handleAddressSelect = (e) => {
    const inputAddressIndex = +e.target.value;
    this.setState({inputAddressIndex});
  }

  handleCountryChange = formProps => () => {
    formProps.setFieldValue('state', '');
  }

  renderAddressInput = (formProps) => {
    const { saving, fields } = this.props;
    const country = formProps.values.countryCode;

    return (
      <>
        <CountrySelect
          fieldName="countryCode"
          excludeEmbargoed
          clearable
          required
          disabled={saving}
          onChange={this.handleCountryChange(formProps)}
          label={<Text field={fields.EditModeCountry} />}/>
        <InputField
          fieldName="address1"
          required
          disabled={saving}
          maxLength={100}
          label={<Text field={fields.EditModeAddress}/>}/>
        <InputField
          fieldName="address2"
          disabled={saving}
          maxLength={100}
          label={<Text field={fields.EditModeAddressOptional} />}/>
        <InputField
          fieldName="city"
          required
          maxLength={50}
          disabled={saving}
          label={<Text field={fields.EditModeCityDistrict} />}/>
        <Row>
          <Col sm='7' xl='9'>
            <StateSelect
              fieldName="state"
              required
              disabled={saving}
              excludeEmbargoed
              maxLength={50}
              countryCode={country}
              label={<Text field={fields.EditModeStateProvince} />}/>
          </Col>
          <Col sm='5' xl='3'>
            <InputField
              fieldName="postalCode"
              required
              disabled={saving}
              maxLength={25}
              label={<Text field={fields.EditModeZipPostalCode} />}/>
          </Col>
        </Row>
      </>
    );
  }

  renderAddressOption = (instance, formProps) => (address, addressIndex) => {
    return (
      <div key={addressIndex}>
        {instance.renderAddressInput(formProps)}
      </div>
    )
  }

  renderSingleAddress =  (instance, formProps)  => {
    return (
      <div>
        {instance.renderAddressInput(formProps)}
      </div>
    );
  }

  handleFocus = () => {
    this.setState({
      isFormStart: true,
    });
  }

  renderForm = formProps => {
    const { saving, fields, sitecoreListsSettings, isRetakeFlow, getDateOfBirth, nameOnIdentification } = this.props;
    const { addresses, hasSavedAddresses } = this.state;
    const monthList = sitecoreListsSettings ? sitecoreListsSettings.Months.map(item => item.name) : [];
    const disableFields = this.IsEligibleToPayFlow() && isRetakeFlow;
    const disableNameUpdate = disableFields && nameOnIdentification?.lastName && nameOnIdentification?.firstName;
    return (
      <Form className="edit-mode" onFocus={this.handleFocus}>
        <FormTracking
          formik={formProps}
          formName="address exam details"
          formStartState={this.state.isFormStart}
        />
        <h2><Text field={fields.EditModeNameOnIdentificationTitle} /></h2>
        <div>
          <div>
            <RichText field={fields.EditModeTitleDescription} className="help-text w-75" />
          </div>
          <div className='font-weight-bold'>
            <Text field={fields.EditModeAcceptedFormsOfIdentificationTitle} />
            {' '}
            <IdentificationFormsTooltip fields={fields} />
          </div>
        </div>
        <div className='mt-3'>
          <h4><Text field={fields.ViewModeNameOnIdentificationTitle} /></h4>
          <FieldGroup>
            <div className="edit-mode__input-group">
              <InputField
                fieldName="lastName"
                disabled={saving || (disableNameUpdate)}
                maxLength={30}
                label={<Text field={fields.EditModeLastName} />}
              />
            </div>
            <div className="edit-mode__input-group">
              <InputField
                fieldName="firstName"
                disabled={saving || (disableNameUpdate)}
                maxLength={20}
                label={<Text field={fields.EditModeFirstName} />}
              />
            </div>
            <div className="edit-mode__input-group">
              <InputField
                fieldName="middleName"
                disabled={saving || disableNameUpdate}
                maxLength={20}
                label={<Text field={fields.EditModeMiddleName} />}
              />
            </div>
            <div className="edit-mode__input-group">
              <DateSelect
                dateField="birthdate"
                monthList={monthList}
                disabled={saving || (getDateOfBirth && disableFields)}
                dateLabel={<Text field={fields.EditModeBirthDate} />}
                monthLabel={<Text field={fields.EditModeBirthMonth} />}
                yearLabel={<Text field={fields.EditModeBirthYear} />}
                required={!this.IsEligibleToPayFlow()}
              />
            </div>
            <div className='circle-alert-info-panel d-flex flex-row'>
              <CircleAlertIcon className="circle-alert-info-icon" />
              <div className='pl-3'>
                <RichText field={fields.WarningInfo} />
                <RichText field={fields.EditModeNameFieldDisabledMessage}  hidden={!disableNameUpdate} />
              </div>
            </div>
          </FieldGroup>
        </div>
        <div className='mt-5'>
          <h4 className="mb-2"><Text field={fields.EditModeTitle} /></h4>
          {hasSavedAddresses ? addresses.map(this.renderAddressOption(this, formProps)) : this.renderSingleAddress(this,formProps)}
        </div>
        <div className="d-flex align-items-end mt-4">
          <Button
            variant="outline-primary"
            disabled={saving}
            onClick={this.cancelEditing}
            titleText={i18n.t('cert-app.Common.Cancel')}
          />
          <SaveButton
            saving={saving}
            label={i18n.t('cert-app.ExamDetails.Address.Save')}
            onClick={this.handleSaveClick(formProps)}
          />
        </div>
      </Form>
    );
  }

  render() {
    const { addresses, inputAddressIndex, initialized } = this.state;
    const { nameOnIdentification, getDateOfBirth } = this.props;
    if (!initialized) {
      return null;
    }

    const inputAddress = addresses[inputAddressIndex];

    const initialValues = {
      ...inputAddress,
      ...nameOnIdentification,
      selectedAddressType: 'Home',
      birthdate: getDateOfBirth,
    };

    return (
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
        render={this.renderForm}
      />
    )
  }
}

const mapStateToProps = state => ({
  countryOrigin: getCountryOrigin(state),
  getTypedProfileAddress: type => getTypedProfileAddress(type)(state),
  countriesData: getAllCountries(getCountriesInfo(state)) || getFilteredCountries(getCountriesInfo(state)),
  getDateOfBirth: getProfileDob(state),
  sitecoreListsSettings: getListsSettings(state),
  isRetakeFlow: isRetakeFlow(state),
})

const mapDispatchToProps = dispatch => ({
  setIsOpen: (isOpen) => dispatch(uiActions.setIsOpen(isOpen)),
  saveProfileAddress: data => dispatch(apiProfileActions.saveAddress(data)),
  saveAppAddress: data => dispatch(apiAppActions.saveAddress(data)),
  saveNameOnIdentification: (data) => dispatch(nameApiActions.saveNameOnIdentification(data)),
});

const addressEdit = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressEdit);

export default addressEdit;
