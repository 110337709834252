import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getApplication } from "foundation/Application/client/Application/accessors";
import * as actions from "foundation/Application/client/Application/actions";
import * as loaderActions from "foundation/FormFields/client/components/PageLoader/actions";
import { getSitecoreContext } from 'foundation/Infrastructure/client/SitecoreContext/setSitecoreContext';

class SubmitQueue extends React.Component {
  state = {
    intervalId: null,
    callCount: 0,
    redirected:false,
  };

  componentDidMount() {
    const { appData, fetchOpenApp } = this.props;
    this.props.showLoader();
    if(!appData)
    {
      fetchOpenApp();
    }
    if(this.state.intervalId === null)
    {
      const intervalId = setInterval(this.callAppData, 1500);
      this.setState({intervalId});
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.setState({intervalId:null});
  }

  callAppData = () => {
    const {fetchOpenApp} = this.props;
    fetchOpenApp();
    this.setState({callCount: this.state.callCount + 1});
  }

  redirectByStatus = (appStatus, isChVendor) => {
    const { history } = this.props;
    const { appRedirects } = getSitecoreContext();
    const redirectUrls = appRedirects.redirectLinks;
    if(!this.state.redirected)
    {
      this.setState({redirected: true});
      if(redirectUrls[appStatus])
      {
        history.push(`${redirectUrls[appStatus]}?isChVendor=${isChVendor}`);
      }else {
        history.push(`${redirectUrls.Sorry}?errorcode=SubmitFailed`);
      }
    }
  }

  componentDidUpdate() {
    const { appData } = this.props;
    if(appData)
    {
      if(this.state.callCount > 15 || appData.applicationStatusEnum !== 'Submitted')
      {
        const activeExam =  appData._embedded.exams.resources.find(exam => exam.isActive === true);
        const isChVendor = activeExam.examVendorEnum==='ATA';
        this.redirectByStatus(appData.applicationStatusEnum,isChVendor);
      }
    }
  }

  render() {
    return (<div />);
  }
}

const mapStateToProps = state => ({
  appData: getApplication(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOpenApp: (data) => dispatch(actions.fetchOpenApplication(data)),
  showLoader: () => dispatch(loaderActions.showPageLoader()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmitQueue));

