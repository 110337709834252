import { ApiStatus } from "foundation/CertificationApiCore/client/constants";
import * as actions from "./actions";

const initialState = {
  status: ApiStatus.Idle,
  entity: null,
  examEntity: null,
  error: null,
};

const examFormatReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case actions.GET_EXAMFORMATS_SUCCESS:
    return {
      ...state,
      entity: action.payload,
      status: ApiStatus.Success,
    };
  case actions.SAVE_EXAMFORMAT_SUCCESS:
  case actions.SAVE_EXAMPILOT_OPTIN_SUCCESS:
    return {
      ...state,
      examEntity: action.payload,
      status: ApiStatus.Success,
    };
  case actions.GET_EXAMFORMATS_REQUEST:
  case actions.SAVE_EXAMFORMAT_REQUEST:
  case actions.SAVE_EXAMPILOT_OPTIN_REQUEST:
    return {
      ...state,
      status: ApiStatus.Submitting,
      error: null,
    };
  case actions.GET_EXAMFORMATS_FAILURE:
  case actions.SAVE_EXAMFORMAT_FAILURE:
  case actions.SAVE_EXAMPILOT_OPTIN_FAILURE:
    return {
      ...state,
      status: ApiStatus.Failure,
      error: action.payload,
    };
  default:
    return state;
  }
};

export default examFormatReducer;
