import orchestrationApiService from 'foundation/Profile/orchestration-api-service';

// eslint-disable-next-line import/prefer-default-export
export const fetchAccreditedUniversities = async () => {
  const request = {
    url: '/api/metadata/accreditedUniversities',
  };
  const response = await orchestrationApiService.get(request);
  return response.data;
};

// eslint-disable-next-line import/prefer-default-export
export const fetchAccreditedUniversity = async (id) => {
  const request = {
    url: `/api/metadata/accreditedUniversities/${id}`,
  };
  const response = await orchestrationApiService.get(request);
  return response.data;
};