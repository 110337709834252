import { isPageSimulation } from 'foundation/SitecoreUtils/client';
import orchestrationApiService from 'foundation/Profile/orchestration-api-service';
import mockData from './mock';

// eslint-disable-next-line import/prefer-default-export
export const fetchProfile = async () => {
  if (isPageSimulation()) {
    return mockData;
  }

  const request = {
    url: `/api/profile/my`,
  };
  const response = await orchestrationApiService.get(request);

  return response.data;
};

export const isAdminProfile = async (data) => {
  if (isPageSimulation()) {
    return false;
  }

  const request = {
    url: `/api/profile/${data.adminPersonId}/isAdmin?encryptedText=${data.ecryptedText}`,
  };
  const response = await orchestrationApiService.get(request);

  return response.data;
};

export const updateProfile = async params => {
  const request = {
    url: params.url,
    data: params.data,
  };
  const response = await orchestrationApiService.put(request);
  return response.data;
};