import orchestrationApiService from 'foundation/Profile/orchestration-api-service';


export const getCompanies = async (data) => {
  const searchTerm = data.searchTerm.replace(/[^a-zA-Z0-9 ]/g, "");
  const request = {
    url: `/api/company/search/${searchTerm}`,
  }
  const response = await orchestrationApiService.get(request);
  return response.data?.companies;
}