export default {
  "orderType": 1,
  "credential": 1,
  "examType": 1,
  "retake": "N",
  "studentBundle": "N",
  "personId": 6013137,
  "applicationId": 5022567,
  "examId": 3413644,
  "countryCode": "AUS",
  "landingPageUrl": "https://www.erpint.pmi.org/store/checkout/cart",
}
