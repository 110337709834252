import applicationApiService from 'foundation/Application/application-api-service';

export const logException = async params => {
  const data = {
    "logLevel": 4,
    "logMessage": params.logMessage,
    "pageUrl": window.location.href,
    "source": JSON.stringify(params.source),
  }
  const request = {
    url: `/api/Logging/log`,
    data};
  const response = await applicationApiService.post(request);
  return response.data;
};