export default {
  "certificationType": "PMP",
  "applicationStatus": "Pending",
  "certifications": [],
  "requirementsMet": false,
  "explanation": "Requirements have not been met for this application; Education requirement met: True; Experience requirement met: False; ",
  "elapsedTime": "00:00:01.3017108",
  "educationRequirements": {
    "requirementMet": true,
    "hoursRequired": 35,
    "hoursTotal": 36,
    "hoursNeeded": 0,
    "creditedHours": 0,
    "additionalHoursRequired": 0,
    "explanation": "35 education hours are required for this application; User has submitted a total of 36.00 education hours for this application; The education requirement for this application has been met.",
    "elapsedTime": "00:00:00.1913051",
  },
  "experienceRequirements": {
    "Project": {
      "requirementMet": false,
      "monthsRequired": 60,
      "monthsTotal": 0,
      "monthsQualified": 0,
      "monthsNeeded": 60,
      "experienceDetails": [],
      "hoursRequired": 7500,
      "hoursTotal": 0,
      "hoursQualified": 0,
      "hoursNeeded": 7500,
      "qualifiedDomainHours": {
        "Closing the Project": 0,
        "Executing the Project": 0,
        "Planning the Project": 0,
        "Initiating the Project": 0,
        "Controlling and Monitoring the Project": 0,
      },
      "domainHoursRequirementMet": false,
      "prorated": true,
      "proratedMonths": 96,
      "explanation": "60 months of Project experience are required for this application; 7500 domain hours of Project experience are required for this application; The Project experience requirement for this application has not been met and 60 experience months are still needed; The Project experience requirement for this application has not been met and 7500 experience domain hours are still needed; The Project experience requirement for this application has not been met.  At least 1 hour of experience is required for each domain; ",
      "elapsedTime": "00:00:00.0000343",
    },
  },
  "experienceSummariesRequirements": {
    "requirementMet": true,
    "totalQuestions": 0,
    "totalAnswered": 0,
    "totalAnswersNeeded": 0,
    "explanation": "No experience summary questions found/required for this application.",
    "elapsedTime": "00:00:00.0684485",
    "answerDetails": [
      {
        statementCollectionId: 54438,
        answerId: 99396,
        questionId: 29,
        questionTitle: 'Strategic Alignment',
        isAnswered: true,
      },
      {
        statementCollectionId: 54438,
        answerId: 99399,
        questionId: 30,
        questionTitle: 'Governance',
        isAnswered: true,
      },
      {
        statementCollectionId: 54438,
        answerId: 99409,
        questionId: 31,
        questionTitle: 'Portfolio Performance',
        isAnswered: true,
      },
      {
        statementCollectionId: 54438,
        answerId: 99410,
        questionId: 32,
        questionTitle: 'Portfolio Risk Management',
        isAnswered: true,
      },
      {
        questionId: 33,
        questionTitle: 'Communications Management',
        isAnswered: false,
      },
    ],
  },
  "_links": {
    "self": {
      "href": "https://cert-int-eastus-application-apiapp.azurewebsites.net/api/Applications/4939032/requirements-status",
      "allowed": [
        "Get",
      ],
    },
  },
}