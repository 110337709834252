// import update from 'immutability-helper';
import { ApiStatus } from "foundation/CertificationApiCore/client/constants";
import * as actions from "./actions";

const initialState = {
  status: ApiStatus.Idle,
  entity: null,
};

const updateDOB = (oldEntity,partialEntity) => {
  const updatedEntity = oldEntity;
  if(partialEntity.birthdate)
  {
    updatedEntity.birthdate = partialEntity.birthdate;
  }
  return updatedEntity;
}

const profileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case actions.FETCH_PROFILE_REQUEST:
    return {
      ...initialState,
      status: ApiStatus.Fetching,
    };
  case actions.FETCH_PROFILE_SUCCESS:
    return {
      entity: action.payload,
      status: ApiStatus.Success,
    };
  case actions.SAVE_PROFILE_SUCCESS:
    return{
      entity: updateDOB(state.entity, action.payload),
      status: ApiStatus.Success,
    }
  case actions.FETCH_PROFILE_FAILURE:
    return {
      ...state,
      status: ApiStatus.Failure,
    };
  default:
    return state;
  }
};

export default profileReducer;
