import orchestrationApiService from 'foundation/Profile/orchestration-api-service';

export const fetchPhones = async url => {
  const request = { url };
  const response = await orchestrationApiService.get(request);
  return response.data;
};

export const createPhone = async params => {
  const request = {
    url: params.url,
    data: params.data,
    handledErrorCode: 'PROF055',
  };
  const response = await orchestrationApiService.post(request);
  return response.data;
};

export const updatePhone = async params => {
  const request = {
    url: params.url,
    data: params.data,
    handledErrorCode: 'PROF055',
  };
  const response = await orchestrationApiService.put(request);
  return response.data;
};

