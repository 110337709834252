import { createAction } from "redux-actions";

export const GET_EXAMFORMATS_REQUEST = "GET_EXAMFORMATS_REQUEST";
export const GET_EXAMFORMATS_SUCCESS = "GET_EXAMFORMATS_SUCCESS";
export const GET_EXAMFORMATS_FAILURE = "GET_EXAMFORMATS_FAILURE";

export const SAVE_EXAMFORMAT_REQUEST = "SAVE_EXAMFORMAT_REQUEST";
export const SAVE_EXAMFORMAT_SUCCESS = "SAVE_EXAMFORMAT_SUCCESS";
export const SAVE_EXAMFORMAT_FAILURE = "SAVE_EXAMFORMAT_FAILURE";

export const SAVE_EXAMPILOT_OPTIN_REQUEST = "SAVE_EXAMPILOT_OPTIN_REQUEST";
export const SAVE_EXAMPILOT_OPTIN_SUCCESS = "SAVE_EXAMPILOT_OPTIN_SUCCESS";
export const SAVE_EXAMPILOT_OPTIN_FAILURE = "SAVE_EXAMPILOT_OPTIN_FAILURE";

export const getExamFormats = createAction(GET_EXAMFORMATS_REQUEST, data => data);
export const getExamFormatsSuccess = createAction(GET_EXAMFORMATS_SUCCESS, data => data);
export const getExamForamtsFailure = createAction(GET_EXAMFORMATS_FAILURE, data => data);

export const saveExamFormat = createAction(SAVE_EXAMFORMAT_REQUEST, data => data);
export const saveExamFormatSuccess = createAction(SAVE_EXAMFORMAT_SUCCESS, data => data);
export const saveExamForamtFailure = createAction(SAVE_EXAMFORMAT_FAILURE, data => data);

export const saveExamPilotOptIn = createAction(SAVE_EXAMPILOT_OPTIN_REQUEST, data => data);
export const saveExamPilotOptInSuccess = createAction(SAVE_EXAMPILOT_OPTIN_SUCCESS, data => data);
export const saveExamPilotOptInFailure = createAction(SAVE_EXAMPILOT_OPTIN_FAILURE, data => data);