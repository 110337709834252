import React from 'react';
import { connect } from 'react-redux';
import { LinkButton } from '@pmi/dsm-react-bs4';
import ProgressBar from 'feature/PageComponents/client/components/ProgressBar';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getGlobalSettings } from 'foundation/SitecoreSettings/client/accessors';
import "./fileUpload.scss";

class FileDropZone extends React.Component {

state = {
  isFileRequest: false,
  progress:0,
};

constructor(props) {
  super(props);
  this.fileInputRef = React.createRef();
  this.ErrorPanelRef = React.createRef();
}

dragOver = (e) => {
  e.preventDefault();
}

dragEnter = (e) => {
  e.preventDefault();
}

dragLeave = (e) => {
  e.preventDefault();
}

fileDrop = (e) => {
  e.preventDefault();
  // avoid mulitple file drop
  if(!this.state.isFileRequest)
  {
    const files = e.dataTransfer.files;
    if (files.length) {
      this.handleFiles(files);
    }
  }
}

filesSelected = () => {
  const fileCtl = document.getElementById("file-drop-zone-ctl");
  if (fileCtl.files.length) {
    this.handleFiles(fileCtl.files);
  }
}

toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
    reader.onerror = error => reject(error);
  });


handleFiles = async (files) => {
  if(files.length > 0) {
    if (this.validateFile(files[0])) {
      // const apiParam = {
      //   "fileContent":files[0],
      //   "onSuccess":this.onSuccessUpload,
      //   "customErrorHandling":this.onFailureUpload,
      // }
      let type = files[0].type;
      if (type.indexOf('/') !== -1) {
        type = type.substring(type.indexOf('/') + 1);
      }
      const fileContent = await this.toBase64(files[0]);
      this.props.formProp.setFieldValue("documentContent",fileContent);
      this.props.formProp.setFieldValue("documentInfo.fileName",files[0].name);
      this.props.formProp.setFieldValue("documentMetadata",{
        DocumentType: type,
        DocumentName: files[0].name,
      });
      this.setState({
        isFileRequest: true,
      });
      this.updateProgress();
      this.onSuccessUpload();
    }
  }
}

onFailureUpload = () => {
  const {fields} = this.props;
  clearInterval(this.interval);
  this.setState({
    isFileRequest: false,
  });
  this.ErrorPanelRef.current.innerText = fields.ForbiddenErrorMessage.value;
}

onSuccessUpload = () => {
  this.props.onClose();
}

componentWillUnmount() {
  clearInterval(this.interval);
}

updateProgress =  () => {
  this.interval = setInterval(() => {
    if(this.state.progress >= 80)
    {
      clearInterval(this.interval);
    }
    else
    {
      this.setState(prevState => ({
        progress: prevState.progress + 5,
      }));
    }
  }, 100);
}

validateFile = (file) => {
  const {fields, sitecoreSettings} = this.props;
  const DEFAULT_VALID_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/tiff', 'application/pdf'];
  const validFileType = sitecoreSettings?.auditSettings?.fileType?.value ?? DEFAULT_VALID_TYPES;
  const DEFAULT_FILE_SIZE = 10000000;
  const fileSize = sitecoreSettings?.auditSettings?.fileSize?.value ?? DEFAULT_FILE_SIZE;
  if (validFileType.indexOf(file.type) === -1) {
    this.ErrorPanelRef.current.innerText = fields.FileTypeErrorMessage.value;
    return false;
  }
  if(file.size > fileSize)
  {
    this.ErrorPanelRef.current.innerText = fields.FileSizeErrorMessage.value;
    return false;
  }
  this.ErrorPanelRef.current.innerText = "";
  return true;
}

fileInputClicked = () => {
  this.fileInputRef.current.click();
}

render() {
  const {isFileRequest, progress} = this.state;
  const {fields} = this.props;
  return (
    <>
      <div className="container">
        <div className="drop-container" onDragOver={this.dragOver}
          onDragEnter={this.dragEnter}
          onDragLeave={this.dragLeave}
          onDrop={this.fileDrop}>
          <div className="drop-message">
            <input
              id="file-drop-zone-ctl"
              className="dropzone-file-input"
              type="file"
              multiple={false}
              onChange={this.filesSelected}
              disabled={isFileRequest}
              ref={this.fileInputRef}
            />
            <div>
              <Text field={fields.Description} /> <LinkButton onClick={this.fileInputClicked} titleText={fields.FileOpenContent.value} className="fileUploadBtn" />
            </div>
            <div className="mt-5">
              <Text field={fields.FileTypeAndSizeMessage} />
            </div>
          </div>
        </div>
        <div ref={this.ErrorPanelRef} className="text-danger mt-2" />
        <div className={isFileRequest ? "d-block mt-5 uploading-panel" : "d-none mt-5 uploading-panel"}>
          <h4><Text field={fields.UploadingHeader} /></h4>
          <ProgressBar bgcolor="#4f17a8" completed={progress} className="mt-4" />
        </div>
      </div>
    </>
  )
}
}

const mapStateToProps = state => ({
  sitecoreSettings: getGlobalSettings(state),
});

export default connect(mapStateToProps, null)(FileDropZone);
