export default {
  "capM2013CutOverDate": "2013-07-01T00:00:00",
  "certApplicationLink": "https://certification.erpint.pmi.org/",
  "certPanelReviewLink": "https://certification.erpint.pmi.org/Panelreview/",
  "cbtLink": "http://www.prometric.com/",
  "pbtLink": "http://www.prometric.com/pmi",
  "cbtHandbookUrl": "",
  "pbtHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/paper-based-testing-handbook.pdf",
  "ccrRenewalApplicationUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/certification-renewal-form.pdf",
  "ccrAuditInstructionsURL": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/audit-instructions.pdf",
  "codeOfEthicsURL": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/ethics/pmi-code-of-ethics-american-english.pdf?sc_lang_temp=en",
  "manualGroupChangeForm": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/exam-change-request-form.pdf",
  "repAdditionalOffices": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/learning/rep/rep-application.pdf",
  "enableDebugging": false,
  "percentToAuditCAPM": 0,
  "percentToAuditPMP": 0,
  "percentToAuditPgMP": 10,
  "percentToAuditPMI_ACP": 10,
  "percentToAuditPMI_RMP": 10,
  "percentToAuditPMI_SP": 10,
  "percentToAuditPfMP": 100,
  "percentToAuditPMI_PBA": 10,
  "percentToAuditAH_MC": 0,
  "percentToAuditPgMPGACAplication": 0,
  "percentToAuditPMPGACAplication": 100,
  "allowedExamFailures": 3,
  "lockoutPeriodInMonths": 12,
  "numExamResultsShown": 3,
  "maxMonthsToExtendEligibility": 6,
  "dateToSwitchVersions": "2005-09-01T00:00:00",
  "dateToBlackOutUntil": "2005-09-30T00:00:00",
  "specAccommFaxNumber": "1-610-239-2257",
  "specAccommApprovalPeriod": 7,
  "groupExamChangeLeadPeriod": 35,
  "applicationArrayListPriorStatuses": "'ClosedApplicationRejected','ClosedFailAudit','ClosedFailed'",
  "applicationArrayListPriorStatusCutoffMonths": 6,
  "contentFooterText": "PMI, the PMI logo, PMBOK, PfMP, PgMP, PMP, PMI-PBA, PMI-RMP, PMI-SP, the PMP logo, and CAPM are marks or registered marks of the Project Management Institute, Inc.",
  "enablePersonRestriction": false,
  "ptlAllRescheduleTransTypes": "RescheduledCandidate, RescheduledPrometric",
  "ptlAllCancellationTransTypes": "CancelledCandidate, CancelledPrometric, CancelledWithinPenalty",
  "groupExamPBTReplyToAddress": "pbtexams@pmi.org",
  "groupExamSAFEAToAddress": "john.falo@pmi.org",
  "pgMPSuppressPanelReviewNotification": false,
  "enableCAPMSmartStart": true,
  "pmpPinProductId": "12607",
  "pmpPinGrantDateCutOff": "2011-09-01T00:00:00",
  "blockedGroupExamIdentifiers": "0038121711",
  "capmHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/certified-associate-project-management-handbook.pdf",
  "pmpHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/project-management-professional-handbook.pdf",
  "pgMPHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/program-management-professional-handbook.pdf",
  "pgMPExpSummPage": 12,
  "pmI_RMPHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/risk-management-professional-handbook.pdf",
  "pmI_SPHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/scheduling-professional-handbook.pdf",
  "pmI_ACPHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/agile-certified-practitioner-handbook.pdf",
  "pfMPHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/portfolio-management-professional-handbook.pdf",
  "pfMPExpSummPage": 11,
  "pmI_PBAHandbookUrl": "http://www.erpint.pmi.org/-/media/pmi/documents/public/pdf/certifications/professional-business-analysis-handbook.pdf",
  "aH_MCHandbookUrl": "http://www.pmi.org/Certification/~/media/PDF/Certifications/AH_MC_Handbook.ashx",
  "pfMPPanelReviewGuidanceUrl": "http://www.pmi.org/~/media/PDF/Certifications/Panel-Review-Guidance-12-12-13.ashx",
  "pgMPPanelReviewGuidanceUrl": "http://www.erpint.pmi.org/~/media/PDF/Certifications/PgMP-Panel-Review-Rater-Guidance.ashx",
  "ccrsService_EnableForwarding": true,
  "ccrsService_EnableSoftOff": false,
  "ccrsService_SoftOffExceptionMessage": "An error occurred in the legacy CCRS Service.  Please contact PMI Application Support.",
  "enable_SAFEAProcessing": true,
  "safeaDiscountPercentage": 40,
  "safeaTaxAdjustment": 3.36,
  "kendoReportingEnabled": false,
  "examVendorPrometricLandingPage": "http://www.prometric.com/pmi",
  "examVendorPearsonVueLandingPage": "http://www.pearsonvue.com/pmi",
  "examVendorATALandingPage": "https://sandbox.joytest.org/pmi/eligible/exam",
  "examVendorPrometricLocatePage": "http://securereg3.prometric.com/landing.aspx?prg=PMI&path=seatavail",
  "examVendorPearsonVueLocatePage": "http://www.pearsonvue.com/pmi/locate",
  "examVendorPrometricSchedulePage": "http://securereg3.prometric.com/landing.aspx?prg=PMI&path=schd",
  "examVendorPearsonVueSchedulePage": "http://www.pearsonvue.com/pmi",
  "examVendorPrometricReschedulePage": "http://securereg3.prometric.com/landing.aspx?prg=PMI&path=reschdcancel",
  "examVendorPearsonVueReschedulePage": "http://www.pearsonvue.com/pmi",
  "examVendorPrometricStandardAccommodationsURL": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/prometric-standard-accommodations.pdf",
  "examVendorPearsonVueStandardAccommodationsURL": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/vue-standard-accommodations.pdf",
  "examVendorPearsonVueOPInstructionsURL": "http://www.pearsonvue.com/pmi/op",
  "pmP_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/project-management-professional-exam-outline.pdf",
  "pgMP_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/program-management-professional-examination-content-outline.pdf",
  "pfMP_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/portfolio-management-professional-exam-outline.pdf",
  "pmI_PBA_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/professional-business-analysis-exam-outline.pdf",
  "pmI_RMP_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/risk-management-exam-outline.pdf",
  "pmI_SP_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/scheduling-professional-exam-outline.pdf",
  "pmI_ACP_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/agile-certified-exam-outline.pdf",
  "capM_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/certified-associate-project-management-exam-outline.pdf",
  "aH_MC_ExaminationContentOutline": "http://www.pmi.org/-/media/pmi/documents/public/pdf/certifications/agile-hybrid-exam-outline.pdf",
  "certAppRedesignEnabled": true,
  "examVendorATASchedulePage": "https://sandbox.joytest.org/pmi/eligible/exam",
  "enableSkuApiLkp": false,
  "newAdobeAnalytics": false,
}
