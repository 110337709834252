import certificationApiService from 'foundation/Application/certification-api-service';

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
    reader.onerror = error => reject(error);
  });

export const uploadCertificationDocument = async params => {
  let type = params.data.fileContent.type;
  if (type.indexOf('/') !== -1) {
    type = type.substring(type.indexOf('/') + 1);
  }
  const fileContent = await toBase64(params.data.fileContent);
  const apiData = {
    DocumentContent: fileContent,
    DocumentMetadata: {
      DocumentType: type,
      DocumentName: params.data.fileContent.name,
    },
  };
  const request = {
    url: params.url,
    data: apiData,
    customErrorHandling: true,
    customerErrorEvent: params.data.customErrorHandling,
  };
  const response = await certificationApiService.post(request);
  return response.data;
};

export const getCertificationDocument = async params => {
  const request = {
    url: params.url,
  };
  request.responseType = 'blob';
  const response = await certificationApiService.get(request);
  const blob = new Blob([response.data], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  return url;
};

export const getAgileCertificationReference = async params => {
  const request = {
    url: params.url,
  };
  const response = await certificationApiService.get(request);
  return response.data;
};

export const postAgileCertificationDocument = async params => {
  const request = {
    url: params.url,
    data: params.data,
  };
  const response = await certificationApiService.post(request);
  return response.data;
};

export const deleteCertification = async params => {
  const request = {
    url: params.url,
  };
  const response = await certificationApiService.delete(request);
  return response.data;
};