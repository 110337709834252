import applicationApiService from 'foundation/Application/application-api-service';


export const getAvailableExamFormats = async params => {
  const request = {
    url: `${params.url}`,
  };
  const response = await applicationApiService.get(request);
  return response.data;
};

export const saveExamFormat = async params => {
  const request = {
    url: `${params.url}/${params.examFormat}`,
  };
  const response = await applicationApiService.put(request);
  return response.data;
};

export const saveExamPilotOptIn = async params => {
  const request = {
    url: `${params.url}/pilot-optin/${params.optIn}`,
  };
  const response = await applicationApiService.put(request);
  return response.data;
};