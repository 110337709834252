import config from 'foundation/Config/client';

export const setScriptFileElement = (args) => {
  const { id, url, async, setScriptLoaded } = args;
  const scriptElement = document.createElement('script');
  scriptElement.addEventListener('load', () => {
    setScriptLoaded(true);
  });
  scriptElement.id = id;
  scriptElement.src = url;
  scriptElement.async = async;
  return scriptElement;
};

export const setLivePersonIdentifier = () => {
  const widgetConfig = {
    clientId: config.idpClientId,
    idpUrl: config.idpAuthority,
  };
  if (window.pmiWidgetCore) {
    window.pmiWidgetCore.init(widgetConfig);
    const lpConfig = {
      idpUrl: widgetConfig.idpUrl,
      redirectUri: window.location.origin,
    };
    window.IDPLP.lpAccountId = config.livePersonAccountId;
    window.IDPLP.init(lpConfig);
  }
};