import { call, put, takeLatest, select } from "redux-saga/effects";
import { getExamTypeApi, getActiveExamApi } from 'foundation/Application/client/Application/accessors';
import * as actions from "./actions";
import * as examLocationActions from "../ExamLocation/actions";
import * as api from "./api";

function* getAvailableExamFormats() {
  try {
    const state = yield select();
    const apiRequest = {
      url: getExamTypeApi(state),
    };
    const data = yield call(api.getAvailableExamFormats, apiRequest);

    yield put(actions.getExamFormatsSuccess(data));
  } catch (err) {
    yield put(actions.getExamForamtsFailure(err.response.data.detail));
  }
}

function* saveExamFormat(requestData) {
  try {
    const state = yield select();
    const apiRequest = {
      url: getExamTypeApi(state),
      examFormat: requestData.payload,
    };
    const data = yield call(api.saveExamFormat, apiRequest);

    yield put(actions.saveExamFormatSuccess(data));
  } catch (err) {
    yield put(actions.saveExamForamtFailure(err.response.data.detail));
  }
}


function* saveExamPilotOptIn(requestData) {
  try {
    const state = yield select();
    const apiRequest = {
      url: getActiveExamApi(state),
      optIn: requestData.payload,
    };
    const data = yield call(api.saveExamPilotOptIn, apiRequest);
    yield put(actions.saveExamPilotOptInSuccess(data));
  } catch (err) {
    yield put(actions.saveExamPilotOptInFailure(err.response.data.detail));
  }
}

function* watchGetExamLocationRequest() {
  yield takeLatest(actions.GET_EXAMFORMATS_REQUEST, getAvailableExamFormats);
  yield takeLatest(examLocationActions.saveExamLocationSuccess, getAvailableExamFormats);
  yield takeLatest(examLocationActions.saveExamLocationCitizenshipSuccess, getAvailableExamFormats);
}

function* watchSaveExamLocationRequest() {
  yield takeLatest(actions.SAVE_EXAMFORMAT_REQUEST, saveExamFormat);
}

function* watchSaveExamOptInRequest() {
  yield takeLatest(actions.SAVE_EXAMPILOT_OPTIN_REQUEST, saveExamPilotOptIn);
}

const examLocationSagaWatchers = [
  watchGetExamLocationRequest,
  watchSaveExamLocationRequest,
  watchSaveExamOptInRequest,
];

export default examLocationSagaWatchers;
