import { createAction } from "redux-actions";

export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";

export const fetchCompanies = createAction(
  FETCH_COMPANIES_REQUEST,
  data => data,
);
export const fetchCompaniesSuccess = createAction(
  FETCH_COMPANIES_SUCCESS,
  data => data,
);
export const fetchCompaniesFailure = createAction(
  FETCH_COMPANIES_FAILURE,
  data => data,
);
