import orchestrationApiService from 'foundation/Profile/orchestration-api-service';

// eslint-disable-next-line import/prefer-default-export
export const fetchCountries = async excludeEmbargoed => {
  const request = {
    url: excludeEmbargoed
      ? '/api/metadata/countries?includeEmbargoed=false'
      : '/api/metadata/countries?includeEmbargoed=true',
  };
  const response = await orchestrationApiService.get(request);
  return response.data;
};

// eslint-disable-next-line import/prefer-default-export
export const fetchCountryByCode = async (code) => {
  const request = {
    url: `/api/metadata/countries/code/${code}`,
  };
  const response = await orchestrationApiService.get(request);
  return response.data;
};

export const fetchStateByCode = async (code) => {
  const request = {
    url: `/api/metadata/countries/code/${code}/states`,
  };
  const response = await orchestrationApiService.get(request);
  return response.data;
};

// eslint-disable-next-line import/prefer-default-export
export const fetchEmbargoedCountries = async () => {
  const request = {
    url: `/api/metadata/countries/embargoed`,
  };
  const response = await orchestrationApiService.get(request);
  return response.data;
};