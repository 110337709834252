import React from 'react';
import { connect } from 'react-redux';
import { getAddressStatus as getAddressProfileStatus } from 'foundation/Profile/client/Address/accessors';
import { getIdentificationAddressStatus as getAddressAppStatus, getHomeIdentificationAddress } from 'foundation/Application/client/Address/accessors';
import { getListsSettings } from 'foundation/SitecoreSettings/client/accessors';
import { getProfileDob } from 'foundation/Profile/client/Profile/accessors';
import { ApiStatus } from "foundation/CertificationApiCore/client/constants";
import { getLocationStatus } from 'feature/ExamDetails/client/components/ExamDetails/accessors';
import { getWorkflowType } from 'foundation/Application/client/certtype-storage';
import {
  getNameOnIdentification,
  getNameOnIdentificationStatus,
} from 'foundation/Application/client/Name/accessors';
import { getIsOpen } from './accessors';
import * as uiActions from "./actions";
import IdentificationAddressView from "./view";
import IdentificationAddressEdit from "./edit";

class Address extends React.Component {
  componentDidMount() {
    const { nameOnIdentification, examAddress, getDateOfBirth, validIdentification } = this.props;
    const identificationData = {
      workflow:  getWorkflowType(),
      name: nameOnIdentification,
      address: examAddress,
      dob: getDateOfBirth,
    }
    validIdentification(identificationData);
  }

  render() {
    const { nameOnIdentification, apiProfileStatus, apiAppStatus, isOpen, fields, examAddress, isValidExamLocation, sitecoreListsSettings } = this.props;

    if (!sitecoreListsSettings) {
      return null;
    }

    if (!fields) {
      return <div className="static-height"/>;
    }
    return isOpen && isValidExamLocation
      ? <IdentificationAddressEdit nameOnIdentification={nameOnIdentification} examAddress={examAddress} saving={(apiProfileStatus === ApiStatus.Submitting) || (apiAppStatus === ApiStatus.Submitting)} fields={fields}/>
      : <IdentificationAddressView nameOnIdentification={nameOnIdentification} address={examAddress} fields={fields} />
  }
}

const mapStateToProps = state => ({
  apiProfileStatus: getAddressProfileStatus(state),
  apiAppStatus: getAddressAppStatus(state),
  isOpen: getIsOpen(state),
  examAddress: getHomeIdentificationAddress(state),
  isValidExamLocation: getLocationStatus(state),
  nameOnIdentification: getNameOnIdentification(state),
  apiIdentificationStatus: getNameOnIdentificationStatus(state),
  sitecoreListsSettings: getListsSettings(state),
  getDateOfBirth: getProfileDob(state),
});

const mapDispatchToProps = dispatch => ({
  validIdentification: (data) => dispatch(uiActions.validIdentification(data)),
});


const address = connect(mapStateToProps, mapDispatchToProps)(Address);

export default address;
