import React from 'react';
import { connect } from 'react-redux';
import * as apiActions from 'foundation/Application/client/ExamFormat/actions';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { RadioGroup } from 'foundation/FormFields/client/components';
import { getActiveExam } from 'foundation/Application/client/Application/accessors';
import { getExamEntity } from 'foundation/Application/client/ExamFormat/accessors';
import './examFormat.scss';

class PilotOnly extends React.Component {

  handleExamPilot = newValue => {
    this.props.saveExamPilotStatus(newValue);
  }

  renderForm = () => {
    const { fields, pilotContent } = this.props;

    const answerOptions = [
      { value: true, label: fields.YesLabelInfo?.value },
      { value: false, label: fields.NoLabelInfo?.value },
    ];

    return (
      <Form>
        <div className="exam-format">
          <h2 className="exam-format__title">
            <Text field={fields.Title} />
          </h2>
          <Row>
            <Col xs={12} md={6}>
              <RichText field={fields.PilotDescription} />
              <RichText field={pilotContent.description} />
            </Col>
            <Col xs={12} md={6}>
              <div className="exam-format__twooption-radio">
                <RadioGroup
                  fieldName="examType"
                  required
                  onChange={this.handleExamPilot}
                  options={answerOptions}
                  disabled={this.props.saving}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    );
  };

  renderFormik = () => {
    const { getAppExamEntity, getLatestExamEntity} = this.props;
    const examEntity = getLatestExamEntity ?? getAppExamEntity;
    const initialValues = {
      examType: examEntity.isPilotExam,
    };

    return (
      <Formik
        initialValues={initialValues}
        render={this.renderForm}
        enableReinitialize
      />
    );
  };

  render() {
    const {disabled } = this.props;
    return (
      <div className={disabled ? "edit-mode" : "edit-mode disable-view"}>
        {this.renderFormik()}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  getAppExamEntity: getActiveExam(state),
  getLatestExamEntity: getExamEntity(state),
});

const mapDispatchToProps = dispatch => ({
  saveExamPilotStatus: (data) => dispatch(apiActions.saveExamPilotOptIn(data)),
});

const pilotOnly = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PilotOnly);

export default pilotOnly;
