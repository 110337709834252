import React from 'react';
import { connect } from 'react-redux';
import * as apiActions from 'foundation/Application/client/ExamFormat/actions';
import { Col, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { RadioGroup } from 'foundation/FormFields/client/components';
import { getExamType } from 'foundation/Application/client/Application/accessors';
import { getExamFormatType } from 'foundation/Application/client/ExamFormat/accessors';
import * as uiActions from "./actions";
import './examFormat.scss';

class MultiExamTypes extends React.Component {

  validationSchema = Yup.object().shape({
    examType: Yup.string()
      .required('please select exam type'),
  });

  handleExamTypeChange = newValue => {
    this.props.saveExamForamt(newValue);
    this.props.setValidity(true);
  };

  renderForm = () => {
    const { fields, examTypes } = this.props;

    const answerOptions = [];

    examTypes.forEach(function(item) {
      answerOptions.push({
        value: item.apiKey.value,
        label: `<strong>${item.title.value}</strong>`,
        description: <RichText field={item.description} className="subDescription" />,
      });
    });

    return (
      <Form>
        <div className="exam-format">
          <h2 className="exam-format__title">
            <Text field={fields.Title} />
          </h2>
          <Row>
            <Col>
              <div className="exam-format__multioption-radio">
                <RadioGroup
                  fieldName="examType"
                  required
                  onChange={this.handleExamTypeChange}
                  options={answerOptions}
                  disabled={this.props.saving}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    );
  };

  renderFormik = () => {
    const { getExamFormatType, getCurrentExamType,examTypes } = this.props;
    const examCurrentType = getExamFormatType ?? getCurrentExamType;
    if(examCurrentType && examTypes.some(item => item.apiKey.value === examCurrentType))
    {
      this.props.setValidity(true);
    }
    const initialValues = {
      examType: examCurrentType,
    };

    return (
      <Formik
        initialValues={initialValues}
        render={this.renderForm}
        validationSchema={this.validationSchema}
        enableReinitialize
        validateOnBlur
        validateOnChange
      />
    );
  };

  render() {
    const {disabled } = this.props;
    return (
      <div className={disabled ? "edit-mode" : "edit-mode disable-view"}>
        {this.renderFormik()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getCurrentExamType: getExamType(state),
  getExamFormatType: getExamFormatType(state),
});

const mapDispatchToProps = dispatch => ({
  saveExamForamt: (data) => dispatch(apiActions.saveExamFormat(data)),
  setValidity: (isValid) => dispatch(uiActions.setValidity(isValid)),
});

const multiExamTypes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiExamTypes);

export default multiExamTypes;
