import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { RadioButton } from '@pmi/dsm-react-bs4';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import * as applicationActions from "foundation/Application/client/Application/actions";
import * as certificationActions from "foundation/Application/client/Certification/actions";
import * as loaderActions from "foundation/FormFields/client/components/PageLoader/actions";
import { getApplication, getApplicationRequestIdle } from 'foundation/Application/client/Application/accessors';
import { getCertification, getCertificationRequestIdle } from 'foundation/Application/client/Certification/accessors'
import withGlobalSettings from 'foundation/SitecoreSettings/client/GlobalSettings';
import withListsSettings from 'foundation/SitecoreSettings/client/ListsSettings';
import withWorkflow from 'foundation/Workflow/client/WorkflowSettings';
import CertificationList from './certificationList';
import './certifications.scss';

class Certifications extends React.Component {
  state={
    agileSelection: false,
  };

  componentDidMount() {
    const { applicationData, applicationRequestIdle, fetchApplicationData, hideLoader,agileCertificationDocumentData, fetchAgileCertification, agileCertificationDocumentRequestIdle } = this.props;
    if (!applicationData && applicationRequestIdle) {
      fetchApplicationData();
    }
    else if(!agileCertificationDocumentData && agileCertificationDocumentRequestIdle)
    {
      fetchAgileCertification();
    }
    hideLoader();
  }

  change = () => {
    this.setState({
      agileSelection: !this.state.agileSelection,
    });
  };

  render() {
    const { fields, agileCertificationDocumentData } = this.props;
    if (!fields) return <div className="static-height" />;
    const createMarkup = (value) => {
      return {__html:  value };
    }
    if(!agileCertificationDocumentData)
    {
      return null;
    }
    const isDocumentAvailable = agileCertificationDocumentData?.resources?.length > 0;
    const answerOptions = [
      {value: 'A', label: fields.YesLabel.value, isSelected: isDocumentAvailable || this.state.agileSelection},
      {value: 'B', label: fields.NoLabel.value, isSelected: !isDocumentAvailable },
    ];
    return (
      <div>
        <div className="heading">
          <Text tag="h2" field={fields.ComponentHeader} />
          <RichText className="heading__description" field={fields.ComponentDescription} />
        </div>
        <div className='form-radio-inline-row'>
          <div className="dsm radio-form-field">
            {answerOptions.map((option, i) => {
              const id =`AgileOptions_${i}`;
              return <span key={id} className={`radio-wrapper agile-options ${option.isSelected  ? `p-radio-box-selected` : ``} p-radio-box`}>
                <RadioButton
                  inputId={id}
                  value={option.value}
                  checked={option.isSelected}
                  onChange={this.change}
                  ariaLabelledby={`${id}-label`}
                />
                <label className="radio-label" htmlFor={id} dangerouslySetInnerHTML={createMarkup(option.label)} />
              </span>
            })}
          </div>
        </div>
        <div>
          <CertificationList certDocumentArray={agileCertificationDocumentData?.resources} fields={fields} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationData: getApplication(state),
  agileCertificationDocumentData: getCertification(state),
  agileCertificationDocumentRequestIdle: getCertificationRequestIdle(state),
  applicationRequestIdle: getApplicationRequestIdle(state),
});

const mapDispatchToProps = dispatch => ({
  fetchApplicationData: () => dispatch(applicationActions.fetchApplication()),
  fetchAgileCertification: () => dispatch(certificationActions.fetchAgileCertification()),
  hideLoader: () => dispatch(loaderActions.hidePageLoader()),
});

export default compose(
  withSitecoreContext(),
  withWorkflow(),
  withGlobalSettings,
  withListsSettings,
  connect(mapStateToProps, mapDispatchToProps),
)(Certifications);