import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withGlobalSettings from 'foundation/SitecoreSettings/client/GlobalSettings';
import { Button } from '@pmi/dsm-react-bs4';
import { Modal, Row } from 'react-bootstrap';
import { getGlobalSettings } from 'foundation/SitecoreSettings/client/accessors';
import { extendAuthToken } from "foundation/Security/client/api";
import { forceIDPRedirect } from 'foundation/Security/client/utils';
import { trackGeneralPageLink } from 'foundation/Analytics/client/AdobeAnalytics/accessors'
import i18n from 'i18next';
import Cookies from 'js-cookie';
import './sessionExpiry.scss';

class SessionExpiry extends React.Component {
  state = {
    isExpired: false,
    showModal: false,
    intervalId: null,
    intermediateIntervalId: null,
    remainingSessionTime: 0,
    inProgress: false,
  };

  componentDidMount(){
    const { globalSettings, sitecoreContext } = this.props;
    if(sitecoreContext.route && !sitecoreContext.route.fields.AllowAnonymous.value && globalSettings && this.state.intervalId === null
      &&  globalSettings.globalSettings.sessionExpiryCheckInterval.value &&  globalSettings.globalSettings.sessionExpiryCheckInterval.value > 0)
    {
      const intervalId = setInterval(this.checkSession, globalSettings.globalSettings.sessionExpiryCheckInterval.value);
      this.setState({intervalId});
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  checkSession = () => {
    const { globalSettings } = this.props;
    if(Cookies.get('authToken') && Cookies.get('authToken').split('.').length > 1)
    {
      const userInfo = JSON.parse(Buffer.from(Cookies.get('authToken').split('.')[1], 'base64').toString());
      if(userInfo)
      {
        const currentDate = new Date();
        const expDate = new Date(userInfo.exp *  1000);
        if(currentDate >= expDate)
        {
          this.setState({showModal: true});
          this.setState({isExpired: true});
        }
        else{
          if(!this.state.intermediateIntervalId)
          {
            const intermediateIntervalId = setInterval(this.checkSession, 2000);
            this.setState({intermediateIntervalId});
          }
          const diff = expDate - currentDate;
          const totalSeconds = Math.round((diff / 1000));
          this.setState({remainingSessionTime: totalSeconds});
          if(!this.state.showModal && globalSettings && totalSeconds <= globalSettings.globalSettings.sessionExpiryWarning.value)
          {
            this.setState({showModal: true});
          }
        }
      }
    }
  }

  buttonText = () => {
    return this.state.isExpired ? i18n.t('cert-app.PageComponents.SessionExpiry.SignIn') : i18n.t('cert-app.PageComponents.SessionExpiry.StaySignedIn');
  }

  extendSession = () =>{
    const linkTracking = {
      linkTitle: this.buttonText(),
      linkModule: 'SessionExpiry',
      targetUrl: '',
    };
    trackGeneralPageLink({linkTracking});
    this.setState({inProgress: true});
    if(!this.state.isExpired)
    {
      //  after intial redirect getting the auth token from the controller
      extendAuthToken()
        .then((authToken) => {
          if (authToken && authToken !== 'null') {
            Cookies.set('authToken', authToken);
            Cookies.set('isPageRefreshPending', true);
            clearInterval(this.state.intermediateIntervalId);
            this.setState({intermediateIntervalId: null});
            this.setState({inProgress: false});
            this.setState({showModal: false});
          }
        })
        .catch((err) => {
          console.error('err', err);
        });
    }
    else
    {
      Cookies.remove('authToken');
      forceIDPRedirect();
    }
  }

  render() {
    const { showModal } = this.state;
    const { sitecoreContext } = this.props;
    if(sitecoreContext.route && sitecoreContext.route.fields.AllowAnonymous.value)
    {
      return "";
    }
    const totalSeconds = this.state.remainingSessionTime;
    const minutes = totalSeconds > 0 ? Math.floor(totalSeconds / 60) : 0;
    const seconds = totalSeconds > 0 ? (totalSeconds % 60).toString().padStart(2, '0') : 0;
    return (
      <>
        <Modal
          show={showModal}
          dialogClassName="dsm sessionExpiryModal"
          aria-labelledby="sessionExpiredModalTitle"
          aria-describedby="sessionExpiredModalDesc"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="sessionExpiredModalTitle">
              <Row className='d-flex'>
                {i18n.t('cert-app.PageComponents.SessionExpiry.SessionExpiry')}
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="sessionExpiredModalDesc">
            <Row className='d-flex'>
              {this.state.isExpired ? i18n.t('cert-app.PageComponents.SessionExpiry.SessionExpiredInfo') : `${i18n.t('cert-app.PageComponents.SessionExpiry.SessionInfo')} ${minutes}:${seconds}`}
            </Row>
          </Modal.Body>
          <Modal.Footer className='sessionExpiryModal__footer'>
            <Row className='d-flex flex-row-reverse'>
              <Button
                onClick={this.extendSession}
                titleText={this.buttonText()}
                size='lg'
                variant='primary'
                disabled={this.state.inProgress}
              />
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  globalSettings: getGlobalSettings(state),
});

export default compose(
  withSitecoreContext(),
  withGlobalSettings,
  connect(mapStateToProps, null),
)(SessionExpiry);