import React from 'react';
import { LinkButton } from '@pmi/dsm-react-bs4';
import i18n from 'i18next';
import CertificationDocumentEdit from './edit';


class CertificationList extends React.Component {
  state = {
    showAddSection: false,
  }

  handleAddCertDocumentClick = () => {
    this.setState({
      showAddSection: true,
    });
  }

  render() {
    const { certDocumentArray, fields, sitecoreSettings, sitecoreListsSettings } = this.props;

    if (!fields) return <div className="static-height"/>;

    if (!certDocumentArray) {
      return null;
    }

    const hasAddForm = !certDocumentArray.length || this.state.showAddSection;

    return (
      <>
        {certDocumentArray.map(certDocument => (
          <CertificationDocumentEdit
            key={certDocument._links.self.href}
            certDocument={certDocument}
            sitecoreSettings={sitecoreSettings}
            sitecoreListsSettings={sitecoreListsSettings}
            fields={fields} />
        ))}
        {hasAddForm &&
          <CertificationDocumentEdit
            addForm
            sitecoreSettings={sitecoreSettings}
            sitecoreListsSettings={sitecoreListsSettings}
            fields={fields}
            isShowCancelButton={certDocumentArray.length > 0} />}
        <LinkButton
          className="link-base d-inline-flex mt-3"
          onClick={this.handleAddCertDocumentClick}
          titleText={i18n.t('cert-app.Experience.Experience.AddAdditionalExperience')}
        />
      </>
    );
  }
}

export default CertificationList;