import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { CountrySelect } from "foundation/FormFields/client/components";
import * as apiActions from 'foundation/Application/client/ExamLocation/actions';
import { getExamLocationStatus} from 'foundation/Application/client/ExamLocation/accessors';
import { ApiStatus } from 'foundation/CertificationApiCore/client/constants';
import { getApplicationType, getExamVendorCountry, getExamCitizenshipCountry } from 'foundation/Application/client/Application/accessors';
import { ReactComponent as CircleAlertIcon } from '@pmi/dsm-react-bs4/dist/assets/icons/24/circle-alert.svg';
import { getWorkflowType } from 'foundation/Application/client/certtype-storage';
import { getMcExamType } from "foundation/Application/client/Exam/accessors";
import { getGlobalSettings } from 'foundation/SitecoreSettings/client/accessors';
import * as uiActions from "./actions";
import './examLocation.scss';

class ExamLocation extends React.Component {
  state = {
    examCitizenSaved: false,
  };

    validationSchema = Yup.object().shape({
      examLocation: Yup.string()
        .required(this.props.fields.CountryIsRequired.value),
      citizenship: Yup.string()
        .required(this.props.fields.CitizenshipIsRequired.value),
    });

    handleExamLocationChange = (formikProps) => (newValue) => {
      if(newValue.value === '' || newValue.value === undefined)
      {
        this.props.setValidity(false);
      }
      else
      {
        this.saveExamLocationCitizenship(newValue.value,formikProps.values.citizenship);
      }
    }

    handleExamCitizenChange = (formikProps) => (newValue) => {
      if(newValue.value === '' || newValue.value === undefined)
      {
        this.props.setValidity(false);
      }
      else
      {
        this.setState({
          examCitizenSaved: true,
        })
        this.saveExamLocationCitizenship(formikProps.values.examLocation,newValue.value);
      }
    }

    saveExamLocationCitizenship = (examLocation,citizenship) =>{
      if(examLocation !== '' && citizenship !== '')
      {
        this.props.saveExamLocationCitizenship({
          examVendorCountryCode: examLocation,
          citizenshipCountryCode: citizenship,
        });
      }
    }

    isValidForm = (formikProps) => {
      if(formikProps.values.examLocation !== undefined && formikProps.values.examLocation !== null && formikProps.values.examLocation !== '' &&
      formikProps.values.citizenship !== undefined && formikProps.values.citizenship !== null && formikProps.values.citizenship !== '')
      {
        return true;
      }
      return false;
    }

    renderForm = (formikProps) => {

      const { fields, sitecoreSettings, applicationType, mcExamType  } = this.props;
      if (!sitecoreSettings) {
        return null;
      }

      if (!applicationType && !mcExamType) {
        return null;
      }

      const certificationType = applicationType || mcExamType;

      if(this.isValidForm(formikProps))
      {
        this.props.setValidity(true);
      }

      const disableCitizenship = getWorkflowType() === 'ETP' && !this.state.examCitizenSaved && formikProps !== null &&  formikProps.values.citizenship !== undefined && formikProps.values.citizenship !== null && formikProps.values.citizenship !== '';

      const applicationCertTypeItem = sitecoreSettings.certTypes
          && sitecoreSettings.certTypes.find(certType => certType.apiKey && (certType.apiKey.value === certificationType));
      const disabledCountryList = applicationCertTypeItem && applicationCertTypeItem.disabledCountries && applicationCertTypeItem.disabledCountries.value;
      return (
        <Form>
          <div className="exam-location">
            <h2 className="exam-location__title">{fields.EditModeExamLocation.value}</h2>
            <div className="row">
              <CountrySelect
                classNameGroup="col-md-12"
                fieldName="examLocation"
                excludeEmbargoed
                required
                disabled={this.props.saving}
                onChange={this.handleExamLocationChange(formikProps)}
                disableCountry={disabledCountryList}
                disableText={fields.UnAvailableCountry.value}
                defaultSelection={fields.DefaultSelectionText.value}
                label={<Text field={fields.EditModeWhereExamQuestion} />}
                avoidStateCall={true}/>
            </div>
            <div className="row">
              <CountrySelect
                classNameGroup="col-md-12"
                fieldName="citizenship"
                required
                disabled={this.props.saving || disableCitizenship}
                onChange={this.handleExamCitizenChange(formikProps)}
                disableCountry={disabledCountryList}
                disableText={fields.UnAvailableCountry.value}
                defaultSelection={fields.CitizenshipDefaultSelectionText.value}
                label={<Text field={fields.EditModeCountryCitizenship} />}
                avoidStateCall={true}/>
            </div>
            <div className='circle-alert-info-panel d-flex flex-row'>
              <CircleAlertIcon className="circle-alert-info-icon" /> <RichText field={fields.WarningInfo} className="pl-3" />
            </div>
          </div>
        </Form>
      );
    };

    renderFormik = () => {
      const initialValues = {
        examLocation: this.props.examVendorCountry,
        citizenship: this.props.examCitizenshipCountry,
      };

      if(initialValues.examLocation === null || initialValues.examLocation === undefined)
      {
        initialValues.examLocation = '';
      }
      if(initialValues.citizenship === null || initialValues.citizenship === undefined)
      {
        initialValues.citizenship = '';
      }

      return (
        <Formik
          initialValues={initialValues}
          validateOnChange
          validationSchema={this.validationSchema}
          render={this.renderForm}
          enableReinitialize
        />
      );
    }

    render() {
      const { fields } = this.props;

      if (!fields) {
        return <div className="static-height"/>;
      }

      return (
        <div className="edit-mode">
          {this.renderFormik()}
        </div>
      )
    }
}

const mapStateToProps = state => ({
  saving: getExamLocationStatus(state) === ApiStatus.Submitting,
  applicationType: getApplicationType(state),
  mcExamType: getMcExamType(state),
  sitecoreSettings: getGlobalSettings(state),
  examVendorCountry: getExamVendorCountry(state),
  examCitizenshipCountry: getExamCitizenshipCountry(state),
});

const mapDispatchToProps = dispatch => ({
  saveExamLocationCitizenship: (data) => dispatch(apiActions.saveExamLocationCitizenship(data)),
  setValidity: (isValid) => dispatch(uiActions.setValidity(isValid)),
});

const examLocation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExamLocation);

export default examLocation;
