import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getAgileCertificationApi } from 'foundation/Application/client/Application/accessors';
import { FETCH_APPLICATION_SUCCESS, FETCH_OPEN_APPLICATION_SUCCESS } from "../Application/actions";
import * as actions from './actions';
import * as api from './api';

function* fetchAgileCertification() {
  const state = yield select();
  const getAgileCertificationUrl = getAgileCertificationApi(state);
  try {
    if(getAgileCertificationUrl)
    {
      const agileCertifications = yield call(api.getAgileCertificationReference, {
        url: getAgileCertificationUrl,
      });
      yield put(actions.fetchAgileCertificationSuccess(agileCertifications));
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.fetchAgileCertificationFailure());
  }
}

const postAgileCertification = function*(action) {
  try {
    const { onSuccess, ...data } = { ...action.payload };
    const { _links } = { ...action.payload };
    const isUpdate = _links && _links['update'] && _links['update'].href;
    const state = yield select();
    const getAgileCertificationUrl = getAgileCertificationApi(state);
    const agileCertificationDocument = yield call(api.postAgileCertificationDocument, { data,
      url:isUpdate ? _links['update'].href : getAgileCertificationUrl,
    });
    yield put(actions.postAgileCertificationSuccess(agileCertificationDocument));
    if (onSuccess !== undefined) {
      onSuccess();
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.postAgileCertificationFailure(action.payload));
  }
};

const deleteCertification = function*(action) {
  try {
    const { _links } = { ...action.payload };
    yield call(api.deleteCertification, { url: _links['self'].href });
    yield put(actions.deleteCertificationSuccess(action.payload));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.deleteCertificationFailure(action.payload));
  }
};

const uploadCertificationDocument = function*(action) {
  try {
    const { _links, onSuccess, ...data } = { ...action.payload };
    const updateCertificationDocument = yield call(api.uploadCertificationDocument, {
      data,
      url: _links['upload'].href,
    });
    yield put(actions.uploadCertificationSuccess(updateCertificationDocument));
    if (onSuccess !== undefined) {
      onSuccess();
    }
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.uploadCertificationFailure(action.payload));
  }
};

const downloadCertificationDocument = function*(action) {
  try {
    const certificationDocument = action.payload.document;
    certificationDocument['url'] = yield call(api.getCertificationDocument, { url: action.payload._links['download'].href });
    const tmplink = document.createElement('a');
    tmplink.href = certificationDocument['url'];
    tmplink.setAttribute('download', action.payload.fileName);
    document.body.appendChild(tmplink);
    tmplink.click();
    tmplink.remove();
    yield put(actions.downloadCertificationSuccess(certificationDocument));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
    yield put(actions.downloadCertificationFailure(action.payload));
  }
};


function* watchFetchAgileCertificationRequest() {
  yield takeLatest(actions.FETCH_AGILE_CERTIFICATION_REQUEST, fetchAgileCertification);
  yield takeLatest(FETCH_APPLICATION_SUCCESS, fetchAgileCertification);
  yield takeLatest(FETCH_OPEN_APPLICATION_SUCCESS, fetchAgileCertification);
}

function* watchPostAgileCertificationRequest() {
  yield takeLatest(actions.POST_AGILE_CERTIFICATION_REQUEST, postAgileCertification);
}

function* watchDeleteCertificationRequest() {
  yield takeLatest(actions.DELETE_CERTIFICATION_REQUEST, deleteCertification);
}

function* watchUploadCertificationDocumentRequest() {
  yield takeLatest(actions.UPLOAD_CERTIFICATION_REQUEST, uploadCertificationDocument);
}

function* watchDownloadCertificationDocumentRequest() {
  yield takeLatest(actions.DOWNLOAD_CERTIFICATION_REQUEST, downloadCertificationDocument);
}

const certificaitonDocumentSagaWatchers = [
  watchFetchAgileCertificationRequest,
  watchPostAgileCertificationRequest,
  watchUploadCertificationDocumentRequest,
  watchDeleteCertificationRequest,
  watchDownloadCertificationDocumentRequest,
];

export default certificaitonDocumentSagaWatchers;
