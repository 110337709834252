import React, { useState, useEffect } from 'react';
import config from 'foundation/Config/client';
import { setScriptFileElement, setLivePersonIdentifier } from './helpers';

const LivePersonIdentifier = () => {
  const [pmiWidgetCoreScriptLoaded, setPmiWidgetCoreScriptLoaded] = useState(false);
  const [idpLivePersonScriptLoaded, setIdpLivePersonScriptLoaded] = useState(false);
  useEffect(() => {
    const pmiWidgetCoreScript = setScriptFileElement({
      id: 'pmi-widget-core',
      url: config.pmiWidgetCoreScript,
      async: true,
      setScriptLoaded: setPmiWidgetCoreScriptLoaded,
    });
    document.head.appendChild(pmiWidgetCoreScript);
    const idpLivePersonScript = setScriptFileElement({
      id: 'idp-liveperson',
      url: config.idpLivePersonScript,
      async: true,
      setScriptLoaded: setIdpLivePersonScriptLoaded,
    });
    document.head.appendChild(idpLivePersonScript);
  }, []);
  useEffect(() => {
    if (pmiWidgetCoreScriptLoaded && idpLivePersonScriptLoaded) {
      setLivePersonIdentifier();
    }
  }, [pmiWidgetCoreScriptLoaded, idpLivePersonScriptLoaded]);
  return React.createElement(React.Fragment, null);
};
export default LivePersonIdentifier;