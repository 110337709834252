import React from 'react';
import Document from 'foundation/Icon/client/document';
import Close from 'foundation/Icon/client/close';
import { Row, Col } from 'react-bootstrap';
import { Button as BaseButton } from 'react-bootstrap';
import { LinkButton } from '@pmi/dsm-react-bs4';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as documentAction from 'foundation/Application/client/Certification/actions';
import "./fileUpload.scss";

class FileUploadView extends React.PureComponent {
  handleDeleteClick = (document) => (e) => {
    e.preventDefault();
    e.disabled=true;
    this.props.deleteAudit(document);
  };

  handleDownloadClick = (document, updatedFileName) => (e) => {
    e.preventDefault();
    const param = {
      "document": document,
      "_links":document._links,
      "fileName": updatedFileName,
    }
    this.props.downloadDocument(param);
    return true;
  };

  render() {
    const { document, disabled, fields, isViewMode } = this.props;
    let updatedFileName =  document?.documentInfo?.fileName;
    if(document.documentMetadata !== null && document.documentMetadata.DocumentName !== undefined)
    {
      updatedFileName = document.documentMetadata.DocumentName;
    }
    //  url is not available and object id is not there
    if((document.url === "" || document.url === undefined) && !document.isDocumentAvailable)
    {
      return null;
    }
    if(isViewMode)
    {
      return (
        <Row className="docDownloadViewPanel">
          <Col md={12}>
            {updatedFileName}
            <BaseButton as="a" variant="link" href={document.url} download={updatedFileName} onClick={document.url === "" || document.url === undefined ? this.handleDownloadClick(document, updatedFileName) : ""} className="view-doc-link btn-md link-base"><Text field={fields.FileDownloadLink} /></BaseButton>
          </Col>
        </Row>
      )
    }
    return (
      <Row className="docDownloadPanel">
        <Col md={11}>
          <BaseButton as="a" variant="link" href={document.url} download={updatedFileName} onClick={document.url === "" || document.url === undefined ? this.handleDownloadClick(document, updatedFileName) : ""} className="with-icon download_link btn-md link-base"><Document /> {updatedFileName}</BaseButton>
        </Col>
        <Col md={1}>
          <LinkButton
            className="link-base remove_document"
            onClick={!disabled ? this.handleDeleteClick(document) : null}
            icon={Close}
            disabled={disabled}
          />
        </Col>
      </Row>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  deleteDocument: (data) => dispatch(documentAction.deleteCertification(data)),
  downloadDocument: (data) => dispatch(documentAction.downloadCertification(data)),
});

export default connect(null, mapDispatchToProps)(FileUploadView);
