import React from 'react';
import { connect } from 'react-redux';
import { Button, LinkButton } from '@pmi/dsm-react-bs4';
import IconPencil from '@pmi/dsm-react-bs4/dist/components/icons/16/PencilIcon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getCountriesInfo, getAllCountries, getFilteredCountries } from 'foundation/Profile/client/Metadata/countries';
import { getProfileDob } from 'foundation/Profile/client/Profile/accessors';
import { getLocationStatus } from 'feature/ExamDetails/client/components/ExamDetails/accessors';
import i18n from 'i18next';
import * as pageActions from "../ExamDetails/actions";

class IdentificationAddressView extends React.PureComponent {

  dateFormat = (dateValue) => {
    const splitedDate = dateValue.split('T')[0].split('-');
    return `${splitedDate[1]}/${splitedDate[2]}/${splitedDate[0]}`
  }

  render() {
    const { openForm, address, countriesData, fields, isValidExamLocation, nameOnIdentification, getDateOfBirth } = this.props;
    const countries = countriesData || [];
    let country = '';
    if (address) {
      const conuntryObj = countries.find(data => data.countryCode3 === address.countryCode) || {};
      country = conuntryObj.country || address.countryCode;
    }
    const cssClass = isValidExamLocation ? "view-mode" : "view-mode disable-view";
    return (
      <div className={cssClass}>
        <div className="view-mode__exam">
          <h2><Text field={fields.EditModeNameOnIdentificationTitle} /></h2>
          {nameOnIdentification && (
            <div className="mt-3 mb-3">
              <b><Text field={fields.ViewModeNameOnIdentificationTitle} /></b>
              <p>{nameOnIdentification.firstName} {nameOnIdentification.middleName} {nameOnIdentification.lastName}</p>
            </div>
          )}
          {getDateOfBirth && (
            <div className="mt-3 mb-3">
              <b><Text field={fields.ViewModeBirthDate} /></b>
              <p>{this.dateFormat(getDateOfBirth)}</p>
            </div>
          )}
          {address && (
            <address>
              <p><b><Text field={fields.ViewModeTitle} /></b></p>
              <p>{address.address1}</p>
              {address.address2 && <p>{address.address2}</p>}
              <p>{address.city}, {address.state} {address.postalCode}</p>
              <p>{country}</p>
            </address>
          )}
        </div>
        {address && nameOnIdentification &&(
          <>
            <LinkButton
              className="btn with-icon link-base d-none d-md-flex"
              onClick={openForm}
              icon={IconPencil}
              titleText={i18n.t('cert-app.ExamDetails.Address.EditIdentificationAddressLabel')}
            />
            <Button
              variant="outline-primary"
              className="d-md-none mt-2"
              onClick={openForm}
              titleText={i18n.t('cert-app.ExamDetails.Address.EditIdentificationAddressLabel')}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countriesData: getAllCountries(getCountriesInfo(state)) || getFilteredCountries(getCountriesInfo(state)),
  isValidExamLocation: getLocationStatus(state),
  getDateOfBirth: getProfileDob(state),
})

const mapDispatchToProps = dispatch => ({
  openForm: () => dispatch(pageActions.openForm('identificationAddress')),
});

const identificationAddressView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentificationAddressView);

export default identificationAddressView;
