import React from 'react';
import { connect } from 'react-redux';
import * as apiActions from 'foundation/Application/client/ExamFormat/actions';
import { getLocationStatus } from 'feature/ExamDetails/client/components/ExamDetails/accessors';
import { getApplication } from 'foundation/Application/client/Application/accessors';
import { getExamFormats } from 'foundation/Application/client/ExamFormat/accessors';
import { getListsSettings } from 'foundation/SitecoreSettings/client/accessors';
import * as uiActions from "./actions";
import PilotOnly from "./pilotOnly";
import MultiExamTypes from "./multiExamTypes";
import './examFormat.scss';

class ExamLocation extends React.Component {
  state = {
    loadExamForamtCompleted: false,
  };

  render() {
    const { fields, fetchExamFormatsAvailable, getAvailableExamFormats, applicationData, getListsSettings, isValidExamLocation } = this.props;
    if(!applicationData || !fields || !getListsSettings)
    {
      return null;
    }

    if(!this.state.loadExamForamtCompleted && (getAvailableExamFormats === null || getAvailableExamFormats === undefined))
    {
      fetchExamFormatsAvailable();
      this.setState({
        loadExamForamtCompleted: true,
      })
    }
    else if(getAvailableExamFormats === null  || getAvailableExamFormats === undefined)
    {
      return null;
    }
    else if(getAvailableExamFormats.some(k => k.includes("Pilot")))
    {
      if(getAvailableExamFormats.length  <= 2)
      {
        const pilotItem = getAvailableExamFormats.find(k => k.includes("Pilot"));
        const pilotItemContent = getListsSettings.ExamTypes.find(k => k.apiKey.value === pilotItem && k.certType.value === applicationData.certificationTypeEnum);
        if(pilotItemContent)
          return (<PilotOnly fields={fields} pilotContent={pilotItemContent} disabled={isValidExamLocation} />)
        return null;
      }
    }
    else if(getAvailableExamFormats.length <= 1)
    {
      return null;
    }
    if(getAvailableExamFormats  != null)
    {
      const configuredExamFormats = getListsSettings.ExamTypes.filter(item => getAvailableExamFormats.includes(item.apiKey.value)
        && (item.certType.value === '' || item.certType.value === applicationData.certificationTypeEnum));
      if(configuredExamFormats.length > 1)
      {
        this.props.showExamFormat(true);
        return (<MultiExamTypes fields={fields} examTypes={configuredExamFormats} disabled={isValidExamLocation}  />);
      }
    }
    return null;
  }
}

const mapStateToProps = state => ({
  applicationData: getApplication(state),
  getAvailableExamFormats: getExamFormats(state),
  getListsSettings: getListsSettings(state),
  isValidExamLocation: getLocationStatus(state),
});

const mapDispatchToProps = dispatch => ({
  fetchExamFormatsAvailable: (data) => dispatch(apiActions.getExamFormats(data)),
  showExamFormat: (isValid) => dispatch(uiActions.showExamFormat(isValid)),
});

const examLocation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExamLocation);

export default examLocation;