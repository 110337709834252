import orchestrationApiService from 'foundation/Profile/orchestration-api-service';

// eslint-disable-next-line import/prefer-default-export
export const fetchEmail = async url => {
  const request = { url };
  const response = await orchestrationApiService.get(request);
  return response.data;
};

export const updateEmail = async params => {
  const request = {
    url: params.url,
    data: params.data,
    handledErrorCode: 'PROF045',
  };
  const response = await orchestrationApiService.put(request);
  return response.data;
};

export const createEmail = async params => {
  const request = {
    url: params.url,
    data: params.data,
    handledErrorCode: 'PROF045',
  };
  const response = await orchestrationApiService.post(request);
  return response.data;
};