import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as api from "./api";

function* fetchCompanies(request) {
  try {
    const data = yield call(api.getCompanies,request.payload);
    yield put(actions.fetchCompaniesSuccess(data));
  } catch (err) {
    yield put(actions.fetchCompaniesFailure());
  }
}

function* watchFetchAllCoursesRequest() {
  yield takeLatest(actions.FETCH_COMPANIES_REQUEST, fetchCompanies);
}

const coursesSagaWatchers = [
  watchFetchAllCoursesRequest,
];
export default coursesSagaWatchers;
