import { ApiStatus } from 'foundation/CertificationApiCore/client/constants';
import * as actions from './actions';

const replaceArrayElement = (array, index, value) => {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
};

const getCertificationIndexByLink = (link, list) => {
  return list.findIndex(
    certDocument => certDocument._links && certDocument._links.self.href.toLowerCase() === link.toLowerCase() // eslint-disable-line comma-dangle
  );
};

const deleteCertification = (array, index) => {
  array.slice(index + 1,1);
  return array;
};
const initialState = {
  status: ApiStatus.Idle,
  entity: null,
};

const certificationDocumentReducer = (state = initialState, action = {}) => {
  /* eslint-disable indent */
  switch (action.type) {
    case actions.FETCH_AGILE_CERTIFICATION_REQUEST:
      return {
        ...initialState,
        status: ApiStatus.Fetching,
      };
    case actions.FETCH_AGILE_CERTIFICATOIN_SUCCESS:
      return {
        entity: action.payload,
        status: ApiStatus.Success,
      };
    case actions.FETCH_AGILE_CERTIFICATOIN_FAILURE:
    case actions.UPLOAD_CERTIFICATION_FAILURE:
    case actions.DOWNLOAD_CERTIFICATION_FAILURE:
    case actions.POST_AGILE_CERTIFICATION_FAILURE:
    case actions.DELETE_CERTIFICATION_FAILURE:
      return {
        ...state,
        status: ApiStatus.Failure,
      };
    case actions.UPLOAD_CERTIFICATION_REQUEST:
    case actions.DELETE_CERTIFICATION_REQUEST:
    case actions.DOWNLOAD_CERTIFICATION_REQUEST:
    case actions.POST_AGILE_CERTIFICATION_REQUEST:
      return {
        ...state,
        status: ApiStatus.Submitting,
      };
    case actions.UPLOAD_CERTIFICATION_SUCCESS:
    case actions.DOWNLOAD_CERTIFICATION_SUCCESS:
      let index = getCertificationIndexByLink(action.payload._links['self'].href, state.entity.resources);
      if (index < 0) {
        index = state.entity.length;
      }
      const updatedResponse = {
        resources: replaceArrayElement(state.entity?.resources, index, action.payload),
        ...state.entity._links,
      }
      return {
        entity: updatedResponse,
        status: ApiStatus.Success,
      };
    case actions.DELETE_CERTIFICATION_SUCCESS:
      let deleteIndex = getCertificationIndexByLink(action.payload._links['self'].href, state.entity.resources);
      if (deleteIndex < 0) {
        deleteIndex = state.entity.length;
      }
      const deletedResponse = {
        resources: deleteCertification(state.entity?.resources, deleteIndex),
        ...state.entity._links,
      }
      return {
        entity: deletedResponse,
        status: ApiStatus.Success,
      };
    case actions.POST_AGILE_CERTIFICATION_SUCCESS:
      let docIndex = getCertificationIndexByLink(action.payload._links['self'].href, state.entity.resources);
      if (docIndex < 0) {
        docIndex = state.entity.length;
      }
      const response = {
        resources: replaceArrayElement(state.entity.resources, docIndex, action.payload),
        ...state.entity._links,
      }
      return {
        entity: response,
        status: ApiStatus.Success,
      };
    default:
      return state;
  }
  /* eslint-enable indent */
};

export default certificationDocumentReducer;
