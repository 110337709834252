import orchestrationApiService from 'foundation/Profile/orchestration-api-service';

export const fetchProfileAcademicEducation = async params => {
  const request = { url: params.url };

  const response = await orchestrationApiService.get(request);
  return response.data;
};

export const createProfileAcademicEducation = async params => {
  const request = {
    url: params.url,
    data: params.data,
  };
  const response = await orchestrationApiService.post(request);
  return response.data;
};

export const updateProfileAcademicEducation = async params => {
  const request = {
    url: params.url,
    data: params.data,
  };
  const response = await orchestrationApiService.put(request);
  return response.data;
};