export default {
  "name":{
    "firstName":"Mock",
    "middleName":"",
    "lastName":"Data",
  },
  "status":"GoodStanding",
  "countryOrigin":"PCN",
  "country":{
    "id":171,
    "countryCode":"PN",
    "countryCode3":"PCN",
    "countryNumber":"612",
    "country":"Pitcairn",
    "internationalDialingCode":"872",
  },
  "_links":{
    "self":{
      "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250",
      "allowed":[
        "Get",
      ],
    },
    "name":{
      "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/name",
      "allowed":[
        "Get",
        "Put",
      ],
    },
    "academicEducation":{
      "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/AcademicEducation",
      "allowed":[
        "Get",
        "Put",
        "Post",
      ],
    },
  },
  "_embedded":{
    "AddressPreferences":{
      "resources":[
        {
          "address":{
            "AddressLocationType":"Residential",
            "address1":"1923 Ardmore Rd NW",
            "address2":"",
            "city":"Atlanta",
            "state":"GA",
            "postalCode":"30309-1817",
            "countryCode":"USA",
          },
          "addressTypeEnum":"Shipping",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Shipping",
              "allowed":[
                "Get",
              ],
            },
            "preference":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Shipping/986315",
              "allowed":[
                "Post",
              ],
            },
            "address":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/986315",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
        {
          "address":{
            "AddressLocationType":"Residential",
            "address1":"1923 Ardmore Rd NW",
            "city":"Atlanta",
            "state":"",
            "postalCode":"30309-1817",
            "countryCode":"PCN",
          },
          "addressTypeEnum":"Home",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Home",
              "allowed":[
                "Get",
              ],
            },
            "preference":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Home/4219866",
              "allowed":[
                "Post",
              ],
            },
            "address":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/4219866",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
        {
          "address":{
            "AddressLocationType":"Residential",
            "address1":"1923 Ardmore Rd NW",
            "city":"Atlanta",
            "state":"",
            "postalCode":"30309-1817",
            "countryCode":"PCN",
          },
          "addressTypeEnum":"Work",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Work",
              "allowed":[
                "Get",
              ],
            },
            "preference":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Work/4219866",
              "allowed":[
                "Post",
              ],
            },
            "address":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/4219866",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
        {
          "address":{
            "AddressLocationType":"Residential",
            "address1":"1923 Ardmore Rd NW",
            "city":"Atlanta",
            "state":"",
            "postalCode":"30309-1817",
            "countryCode":"PCN",
          },
          "addressTypeEnum":"Mailing",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Mailing",
              "allowed":[
                "Get",
              ],
            },
            "preference":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Mailing/4219866",
              "allowed":[
                "Post",
              ],
            },
            "address":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/4219866",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
        {
          "address":{
            "AddressLocationType":"Residential",
            "address1":"1923 Ardmore Rd NW",
            "city":"Atlanta",
            "state":"",
            "postalCode":"30309-1817",
            "countryCode":"PCN",
          },
          "addressTypeEnum":"Billing",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Billing",
              "allowed":[
                "Get",
              ],
            },
            "preference":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred/Billing/4219866",
              "allowed":[
                "Post",
              ],
            },
            "address":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/4219866",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
      ],
      "_links":{
        "self":{
          "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses",
          "allowed":[
            "Get",
            "Post",
          ],
        },
      },
    },
    "Addresses":{
      "resources":[
        {
          "AddressLocationType":"Residential",
          "address1":"1923 Ardmore Rd NW",
          "address2":"",
          "city":"Atlanta",
          "state":"GA",
          "postalCode":"30309-1817",
          "countryCode":"USA",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/986315",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
        {
          "AddressLocationType":"Residential",
          "address1":"1923 Ardmore Rd NW",
          "city":"Atlanta",
          "state":"",
          "postalCode":"30309-1817",
          "countryCode":"PCN",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses/4219866",
              "allowed":[
                "Get",
                "Put",
              ],
            },
          },
        },
      ],
      "_links":{
        "self":{
          "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/addresses",
          "allowed":[
            "Get",
            "Post",
          ],
        },
        "preferred":{
          "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Addresses/preferred",
          "allowed":[
            "Post",
          ],
        },
      },
    },
    "PhoneNumbers":{
      "resources":[
        {
          "PhoneNumberType":"Home",
          "isPrimary":false,
          "countryId":227,
          "phoneCountryCode":"1",
          "phoneNumber":"4043515151",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/phones/4468775",
              "allowed":[
                "Get",
                "Put",
              ],
            },
            "primary":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Phones/primary/4468775",
              "allowed":[
                "Post",
              ],
            },
          },
        },
        {
          "PhoneNumberType":"Cell",
          "isPrimary":true,
          "countryId":227,
          "phoneCountryCode":"1",
          "phoneAreaCode":"223",
          "phoneNumber":"456789012345",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/phones/3267964",
              "allowed":[
                "Get",
                "Put",
              ],
            },
            "primaryself":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Phones/primary",
              "allowed":[
                "Get",
              ],
            },
            "primary":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Phones/primary/3267964",
              "allowed":[
                "Post",
              ],
            },
          },
        },
        {
          "PhoneNumberType":"PaymentMethod",
          "isPrimary":false,
          "countryId":227,
          "phoneCountryCode":"1",
          "phoneNumber":"0000000",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/phones/4508626",
              "allowed":[
                "Get",
                "Put",
              ],
            },
            "primary":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Phones/primary/4508626",
              "allowed":[
                "Post",
              ],
            },
          },
        },
        {
          "PhoneNumberType":"PaymentMethod",
          "isPrimary":false,
          "countryId":227,
          "phoneCountryCode":"1",
          "phoneNumber":"0000000",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/phones/6825562",
              "allowed":[
                "Get",
                "Put",
              ],
            },
            "primary":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Phones/primary/6825562",
              "allowed":[
                "Post",
              ],
            },
          },
        },
      ],
      "_links":{
        "self":{
          "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/phones",
          "allowed":[
            "Get",
            "Post",
          ],
        },
      },
    },
    "Emails":{
      "resources":[
        {
          "isPrimary":true,
          "address":"xxx01Email_mark.t.rogers@pmi.org",
          "_links":{
            "self":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/emails/2577067",
              "allowed":[
                "Get",
                "Put",
              ],
            },
            "primaryself":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Emails/primary",
              "allowed":[
                "Get",
              ],
            },
            "primary":{
              "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/profile/752250/Emails/primary/2577067",
              "allowed":[
                "Post",
              ],
            },
          },
        },
      ],
      "_links":{
        "self":{
          "href":"https://cert-int-eastus-profile-apiapp.azurewebsites.net/api/Profile/752250/emails",
          "allowed":[
            "Get",
            "Post",
          ],
        },
      },
    },
  },
}
