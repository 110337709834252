import gql from 'graphql-tag';

const query = gql`
query getLists($dataFolderPath: String, $listsFolderTemplate: String) {
  data: item(path: $dataFolderPath) {
    lists: children(includeTemplateIDs: [$listsFolderTemplate]) {
      data: children {
        name
        items: children {
          name
          displayName
          apiKey: field(name: "apiKey") {
            value
          }
          ...on AcademicEducationLevel {
            fieldOfStudyAvailable {
              boolValue
            }
            degreeProgramAvailable {
              boolValue
            }
          }
          ... on ExamType {
            title {
              value
            }
            description {
              value
            }
            certType {
              value
            }
          }
        }
      }
    }
  }
}
`;

export default query;
